  .about-page-header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 62vh;
  background: url("https://manmeetawsbucket.s3.eu-north-1.amazonaws.com/dictatorship-oppression-collage-concept+1+(1).png")
    center;
  background-size: cover;
  margin-bottom: 6.8rem;
}

.about-page-header .content {
  position: relative;
  width: 90%;
  height: 100%; 
  transform: translateY(83px);
}
/* .about-page-header .content h1 {
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -1.5rem);
  color: var(--white-color);
  font-size: 3rem;
} */
.about-page-header .content img {
  width: 100%;
  /* border-radius: 20px; */
  position: absolute;
  bottom: 0;
  height: 40%;
}
.about-us-page .bg-img{
  display: none;
}
.about-us-page .main-heading{
  display: block !important;
}
@media (max-width:992px) {
  .about-page-header{
    /* height: 25vh; */
    height: 50vh;
  }
  .about-us-page .w-75{
    width: 100% !important;
  }
  .about-page-header .content{
    transform: translateY(58px);
  }
  .about-page-header .content img{
    /* aspect-ratio: 1/1; */
    height: 40%;
  }
}


@media (max-width:768px) {
  .about-page-header{
    /* height: 25vh; */
    height: 50vh;
    
  }
  
  .about-page-header .content{
    transform: translateY(56px);
  }
}



@media (max-width:576px) {


  .about-page-header .content img {
/* height: 35%; */
height: 50%;

  }

  .about-page-header{
    height:25vh;
    
  }
  .about-page-header .content{
    /* transform: translateY(58px); */
    transform: translateY(38px);
  }
}

@media(max-width:450px){
  
  .about-page-header .content img {
    /* height: 30%; */
    
      }
    }