 .faq-section{
     width: 80% !important;
     /* background-color:  rgb(201, 236, 242) */
 }
 .faq-section h2,h4,h5{
    font-family: "heading" !important;
 } 
 .faq-section h4{
    font-weight: 600;
 }
 .faq-section p{
    font-family: "bodyfont" !important;
    margin-bottom: 1.5rem !important;
    
 }
 .faq-section .term-and-conditions p{
    margin-bottom: 0rem !important;

 }

 .faq-section .top-spacing{
    margin-top: 1.5rem;

 }