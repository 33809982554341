.podcast-section .left-section {
     
}

.podcast-section .left-section .top-section {
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* padding: 1rem; */
    overflow: hidden;
    /* height: 450px; */
    margin-bottom: calc(2.5rem* .5);
}

.podcast-section .left-section .top-section>.img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: var(--main-color-one);
    z-index: -1;
}

.podcast-section .left-section .top-section .text img {
    width: 40%;
    margin-top: 10px;
}

.podcast-section .left-section .top-section .content {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    /* left: 0;
    top: 0; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    padding: 40px;
    /* padding-left: 50px; */
}

.podcast-section .left-section .top-section .content h2 {
    font-size: 2.5rem;
    font-weight: bolder;
}

.podcast-section .left-section .top-section .content p {
    /* width: 70%; */
}

.podcast-section .left-section .top-section .youtube-btn a {
    padding: 10px 30px;
    /* background-color: var(--main-color-two); */
    background: transparent;
    border: 2px solid var(--theme-color);
    color: var(--theme-color) !important;
    font-size: 1.2rem;
    border-radius: 25px;
    text-transform: capitalize;
    font-family: "bodyfontbold" !important;
}

.podcast-section .left-section .bottom-two-cards {
    display: flex;
    justify-content: space-between;
    gap: 0 calc(2.5rem* .5);
}

.podcast-section .left-section .bottom-two-cards .b-card {
    width: 50%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 1/.8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    flex-direction: column;
}

.podcast-section .left-section .bottom-two-cards .b-card div {
    width: 100%;
    background: var(--theme-color-gradiant);
    padding: 1rem;
    color: var(--white-color);
}

.podcast-section .left-section .bottom-two-cards .b-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.podcast-section .left-section .bottom-two-cards .b-card span {
    font-size: .9rem;
}

.podcast-section .left-section .bottom-two-cards .b-card p {
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: 500;
}

.podcast-section .right-card {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    /* padding: 1rem; */
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.podcast-section .right-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    z-index: -1;
}

.podcast-section .right-card div {
    background: var(--theme-color-gradiant);
    padding: 1rem 1.5rem;
    color: var(--white-color);
}

.podcast-section .right-card span {
    font-size: .9rem;
    /* color: #636669; */
}

.podcast-section .right-card p {
    text-transform: capitalize;
    font-size: 1.2rem;
    width: 80%;
    font-weight: 500;
}
@media (max-width:992px) {
    
    .podcast-section .left-section .top-section .content h2{
        font-size: 1.5rem;
    }   
    .podcast-section .left-section .top-section{
        margin-bottom: 3rem;
    }
    .podcast-section .left-section .top-section .content p{
        width: 100%;
    }
    .podcast-section .left-section .top-section .text img {
        width: 40%;
    }
    .podcast-section .left-section .top-section .youtube-btn a{
        font-size: 1rem;
    }
    .podcast-section .left-section .bottom-two-cards {
        
        /* flex-direction: column; */
        flex-direction: row;

    }
    .podcast-section .left-section .bottom-two-cards .b-card{
        /* width: 100%; */
          
        /* margin-bottom: 3rem; */
        /* aspect-ratio: 1/1; */
        /* aspect-ratio: 1/0.7; */
    }

    .podcast-section .right-card{
        height: 100%;
    }


    .podcast-section .right-card,
    .podcast-section .right-card img{
        aspect-ratio: 1/1;
        
        /* height: auto; */
    }
}


@media(max-width:768px){ 
    .podcast-section .left-section .top-section .text img {
        width: 40%;
    }
    

    .podcast-section .left-section .bottom-two-cards .b-card{
        /* width: 100%; */
          
        margin-bottom: 3rem;
        aspect-ratio: 1/0.5; 
    }
    .podcast-section .right-card{
        aspect-ratio: 1/0.467;
        
        /* height: auto; */
    }
    .podcast-section .left-section .top-section .youtube-btn a{
        font-size: 0.9rem;
    }
     
}

@media(max-width:576px){
    .podcast-section .left-section .bottom-two-cards .b-card {
        aspect-ratio: 1/0.7;
    }
    .podcast-section .left-section .bottom-two-cards .b-card {
        /* border-radius: 10px; */
    }
    .podcast-section .right-card  {
        /* border-radius: 10px; */
    }
}

@media(max-width:375px){
.podcast-event-page-podcast-section .main-heading {
            font-size: 1.4rem;
        }
        .podcast-event-page-podcast-section .main-heading span {
            font-size: 2.4rem;
        }
}