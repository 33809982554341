.BannerMaster{
    display: flex;
    width: 40vw;
    height: 50vh;
  
    justify-content: center;
    background-color:  gainsboro;
     transform: translate(70%,5%);
     border-radius: 10px;
}
.BannerMaster .content{
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-top: 1rem;
   

}
.BannerMaster .content h4{
    color: #219ebc;
}
.BannerMaster .content textarea{
    width: 50%;
}

.BannerMaster .content .dropdown{
    margin-top: 1rem;
    padding: 0.5rem;

    width: 50%;
}
.BannerMaster .content button{
    margin-top: 1rem;
     padding: 0rem 0.8rem;
     border-radius: 5px;
     background-color: rgb(199, 199, 253);
 
}