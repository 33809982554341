.about-event-section .main-heading{
    font-size: 3rem;
}
.about-event-section .right-section {
    display: flex;
}

.about-event-section .right-section .left-three-img {
    padding: 1rem;
    border-radius: 20px;
    background: var(--main-color-one);
    width: 40%;
}

.about-event-section .right-section .left-three-img img {
    border-radius: 15px;
    width: 100%;
    height: 160px;
    margin-bottom: 1rem;
    object-fit: cover;
}

.about-event-section .right-section .left-three-img img:last-child {
    margin-bottom: 0;
}

.about-event-section .right-section .right-img {
    width: 60%;
    object-fit: cover;
}
.about-event-section .right-section .right-img a {
    
    /* display: inline-block;
    border-radius: 20px;
    padding: 8px 30px;
    text-align: center;
    background-color: var(--main-color-two);
    text-transform: capitalize; */
    margin-bottom: 2rem;
    /* font-weight: bolder; */
}

.about-event-section .right-section .right-img img {
    border-radius: 20px;
    width: 100%;
    height: 84%;
    object-fit: cover;
}
@media (max-width:992px) {
    .about-event-section .main-heading{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .about-event-section .right-section{
        flex-direction: column;
        margin-top: 3rem;
    }
    .about-event-section .right-section .left-three-img{
        width: 100%;
        margin-bottom: 2rem;
    }
    .about-event-section .right-section .right-img{
        width: 100%;
    }
}