.related-articles-section .main-heading {
    margin-bottom: 2rem;
    font-size: 2rem;
    padding: 0;
    color: var(--font-dark) !important;
}
.related-articles-section .main-heading span{
    color: var(--theme-color);
}

.related-articles-section .edition-card {
    border: 1px solid #e3e3e3;
}

/* .related-articles-section .edition-card:nth-child(3n + 0) {
    border-left: 0;
    border-right: 0;
} */

/* .related-articles-section .edition-card:nth-last-child(+n-3){ */
/* .related-articles-section .edition-card {
    border-top: 0;
} */

/* .related-articles-section .edition-card:nth-child(1),
.related-articles-section .edition-card:nth-child(2),
.related-articles-section .edition-card:nth-child(3),
.related-articles-section .edition-card:nth-child(4) {
    border-top: 1px solid var(--font-dark) !important;
} */

.related-articles-section .edition-card .date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.related-articles-section .edition-card .image img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/0.6;
    object-fit: cover;
    margin-bottom: 1rem;
    /* border-radius: 20px; */
    
}

.related-articles-section .edition-card .box {
    padding: 1.5rem 10px;
}

.related-articles-section .edition-card .box h6 {
    /* text-transform: uppercase; */
    font-size: 0.9rem;
    font-weight: bold;
    /* color: var(--font-light); */
    color: var(--theme-color);
}
.related-articles-section .edition-card .box .desc{
    font-size: 0.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
 
}

@media (max-width:992px) {
    .related-articles-section .main-heading {
        font-size: 2rem;
    }

    .related-articles-section .edition-card {
        border: none;
    }

    .related-articles-section .edition-card .image img{
        object-position: top;
        aspect-ratio: 0.9/0.4;
      }
    .related-articles-section .edition-card .box {
        border: 1px solid #e3e3e3;
        padding: 1rem;
    }
}
@media(max-width:375px){
    .related-articles-section .main-heading {
        font-size: 1.8rem;
    }
}