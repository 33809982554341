.dsa-articles-section {
    background: var(--main-color-one);
    margin-top: 40px;
    display: flex;
    justify-content: center;
    /* padding-bottom: 50px; */
}
.dsa-articles-section .left-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    /* margin-left: 0vw; */
}
.dsa-articles-section .left-section .main-heading h2{
font-family: "headingbold" !important;
}
.dsa-articles-section .left-section .top-section{
    margin-top: 0.2rem;
}
.dsa-articles-section .left-section .top-section b {
    font-family: "bodyfontbold" !important;
}

.dsa-articles-section .left-section .top-section a {
    /* padding: 8px 30px;
    border-radius: 20px;
    color: var(--white-color) !important; */
    margin-top: 1rem;
    /* display: inline-block;
    background: var(--black-color); */
}


.dsa-articles-section .left-section .top-section p{
    
 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 6;
 -webkit-box-orient: vertical;

}

.dsa-articles-section .left-section .bottom-section {
    text-align: right;
    /* margin-top: 12.5rem; */
    margin-top: 4.2rem;
}

.dsa-articles-section .left-section .bottom-section h2 {
    font-size: 2rem;
    font-family: "headingbold" !important;
    text-transform: uppercase;
    margin-bottom: 1rem !important;
    color: var(--font-dark);
}
.dsa-articles-section .left-section .bottom-section p{
    font-size: .9rem;
    
 overflow: hidden;
 text-overflow: ellipsis;
 display: -webkit-box;
 -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;

}
.dsa-articles-section .left-section .bottom-section a{
    color: var(--theme-color) !important;
    font-size: 0.9rem;

}
.dsa-articles-section .left-section .bottom-section a:hover{
    /* color: var(--theme-color) !important; */
    text-decoration: underline !important;
    font-size: 0.9rem;

}
.dsa-articles-section .image {
    height: 98%;
    /* height: 35%; */
    margin-left: 1.9vw;
}

.dsa-articles-section .image img {
    border-radius: 20px;
    width: 98%;
    height: 100%;
    aspect-ratio: 0.8/0.6;
    object-fit: cover;
}
@media (max-width:992px) {
    .dsa-articles-section .left-section .bottom-section h2{
        font-size: 2rem;
    }
    /* .dsa-articles-section .left-section .bottom-section{
        margin: 5rem 0;
    } */
    .dsa-articles-section .left-section .top-section {
        margin-top: 4.2rem;
    }    
    /* dsa-articles-section .image {
        height: 45%;
        margin-left: 1.9vw;
    } */
    
    .dsa-articles-section .image img {
        
        aspect-ratio: 0.8/0.4;
    }
         
}

@media (max-width: 450px) {
    .dsa-articles-section .left-section .bottom-section h2 {
        font-size: 1.5rem;
    }
}