.milestone-section .bg-img {
    position: absolute;
    left: 0;
    top: 1rem;
}

.milestone-section .mySwiper {
    padding-bottom: 3rem;
    padding-top: 3rem !important;
    margin-top: 2rem;
}

.milestone-section .mySwiper .swiper-pagination-bullet-active {
    background: var(--theme-color) !important;
}
.milestone-section .mySwiper .milestone-card {
    padding: 1rem 1.5rem;
    padding-top: 1.9rem;
    border-radius: 50%;
    width: 100%;
    height: auto;
    /* background: var(--main-color-two); */
    background: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
    aspect-ratio: 1/1;
    transition: .3s all;
}

.milestone-section .mySwiper .milestone-card:hover {
    background: var(--theme-color);
    transform: scale(1.05);
}
.milestone-section .mySwiper .milestone-card:hover .count{
    border-color: var(--white-color);
}

.milestone-section .mySwiper .milestone-card .count {
    /* font-size: 2.5rem; */
    font-size: 2.9rem;
    font-weight: 500;
    /* text-decoration: underline;
    text-decoration-color: var(--theme-color); */
    color: var(--theme-color);
    /* text-underline-offset: 10px; */
    /* margin-top: 1rem; */
    /* text-decoration-thickness: 2px; */
    border-bottom: 2px solid var(--theme-color);
    width: 100%;
    font-family: "bodyfontbold" !important;
    margin-bottom: 5px !important;
}

.milestone-section .mySwiper .milestone-card:hover .count {
    color: var(--white-color) !important;
    text-decoration-color: var(--white-color);
}

.milestone-section .mySwiper .milestone-card .content {
    color: #636669;
    font-size: 1.2rem;
    line-height: 1.5rem;
}

.milestone-section .mySwiper .milestone-card:hover .content {
    color: var(--white-color);
}

@media(max-width:576px){
    .milestone-section .w-75 {
        margin-bottom: -2rem !important;
    }
}