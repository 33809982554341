.main-footer {
  background: var(--theme-color);
  position: relative;
  color: var(--white-color);
  margin-top: 8rem !important;
  /* margin-top: 2rem !important; */
  /* height: 52vh; */
  height: auto;
  /* padding-bottom: -20rem !important; */

  /* margin-bottom: 10px; */
}

.main-footer .font-size {
  font-size: 0.8rem;
}
.main-footer .font-size img {
  margin-left: -8px;
  width: 245px;
}
.main-footer .top-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* transform: translateY(-50px); */
   transform: translateY(-44px);
  z-index: 0;
  width: 100%;
  /* background: var(--theme-color); */
}

.main-footer .sm-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.main-footer .sm-links a {
  width: 40px;
  height: 40px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-color) !important;
  border-radius: 50%;
  font-size: 1.4rem;
  background: #992b2b;
}
.main-footer .sm-links a:hover {
  font-size: 1.8rem;
}

.main-footer a {
  color: var(--white-color) !important;
}
.quicklinks li:hover {
  text-decoration: underline;
}
.resources li:hover {
  text-decoration: underline;
}

.main-footer h4 {
  position: relative;
  color: #ecd344;
  width: fit-content;
  padding-bottom: 0.7rem;
  margin-bottom: 1rem !important;
  font-family: "bodyfont" !important;
  font-weight: bolder;
}

.main-footer h4::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 4px;
  border-radius: 10px;
  background: #ecd344;
}

.main-footer .footer-links ul li {
  display: flex;
  /* align-items: center; */
  gap: 15px;
  margin-bottom: 10px;
}

.main-footer .footer-links ul li .icon {
  color: #ecd344;
  width: 2.5rem;
}
.main-footer .footer-links ul li .whatsAppIcon {
  width: 1.3rem;
  margin-top: -4px;
}

.main-footer .footer-links ul li p {
  text-transform: capitalize;
}

.main-footer .footer-links ul li p {
  font-size: 0.9rem;
}

.copyright-section {
  background: #6a0101;
  color: var(--white-color);
}

.copyright-section a {
  color: var(--white-color) !important;
}



@media(max-width:1400px){
  .copyright-section {
    font-size: 0.8rem;
  }

}

@media(max-width:1200px){
  .main-footer{
    height: 100%;
  }
  .copyright-section {
    font-size: 0.6rem;
  }

  

}


@media (max-width:992px) {
   
.main-footer .font-size p{
  width: 46%;
  
}
  .main-footer{
    height: 100%;
  }
  .main-footer h4 {
    margin-top: 2rem !important;
  }
  .main-footer .top-img {
    transform: translateY(-14px);
  }
  .main-footer .footer-links {
    background: var(--theme-color);
  }
  .copyright-section {
    font-size: 1rem;
  }
}
@media(max-width:768px){

  .main-footer .footer-links .quicklinks,
  .main-footer .footer-links .resources{
    display: none;

  }
  .main-footer .footer-links .contact-info {
    width: 100%;

  }
  .main-footer .font-size p{
    width: 60%;
  }
  .copyright-section {
    font-size: 0.8rem;
  }

}

@media(max-width:576px){
  .main-footer .font-size p{
    width: 84%;
    
  }
.copyright-section {
  font-size: 0.7rem;
}

.main-footer .font-size img {
  width:180px
}

}

@media(max-width:450px){
  .main-footer .font-size p{
    width: 92%;
    
  }
}

