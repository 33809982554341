:root {
  --theme-color: #800000;
  --theme-color-gradiant: #800000a8;
  --main-color-one: #d9d9d9;
  --main-color-two: #bcbcbc;
  --black-color: #000;
  --white-color: #fff;
  --border-color: #000;
  --font-dark: #414141;
  --font-light: #a6a6a6;
}

@import url("https://fonts.googleapis.com/css2?family=Beau+Rivage&display=swap");

@font-face {
  font-family: bodyfontbold;
  src: url(./assets/fonts/body/HelveticaNeueLTStd-Hv.otf);
}

@font-face {
  font-family: bodyfont;
  src: url(./assets/fonts/body/HelveticaNeueLTStd-Lt.otf);
}

@font-face {
  font-family: heading;
  src: url(./assets/fonts/heading/BaseNeueTrial-Regular.ttf);
}

@font-face {
  font-family: headingbold;
  src: url(./assets/fonts/heading/BaseNeueTrial-Bold.ttf);
}

body {
  font-family: "bodyfont" !important;
  color: var(--font-light);
}

p {
  margin-bottom: 0 !important;
  letter-spacing: 1px;
}

a {
  text-decoration: none !important;
  color: var(--black-color) !important;
}

ul {
  padding-left: 0 !important;
  list-style: none !important;
  margin-bottom: 0 !important;
}

.section-spacing {
  /* padding: 50px; */
  padding: 80px;
}

/* .bg-section-spacing{
  padding-left: 0;
  padding-right: 0;
  margin: 20px 0;
} */

.bg-theme {
  background: var(--main-color-two);
}

.bg-theme-dark {
  background-color: var(--main-color-two);
}

.bg-theme-light {
  background-color: var(--main-color-one);
}

.main-heading {
  color: var(--theme-color);
}

.main-heading span {
  color: #636669;
}

h1,
h2,
h3,
h4,
h5.h6 {
  font-family: "heading" !important;
  color: var(--font-dark);
  letter-spacing: 2px;
}

.heading-text {
  color: var(--font-light);
}

.main-heading {
  font-family: "headingbold" !important;
  text-transform: uppercase;
}

/* .sub-img {
  object-position: center;
  cursor: pointer;
  } */

.theme-btn {
  padding: 8px 30px;
  border: 2px solid var(--theme-color) !important;
  /* color: var(--theme-color) !important; */
  color: var(--white-color) !important;
  /* background: transparent !important; */
  background: var(--theme-color) !important;
  border-radius: 40px !important;
  font-size: 1.2rem;
  transition: 0.3s all;
  font-weight: bolder;
  display: inline-block;
  width: fit-content;
}

.theme-btn:hover {
  border: 2px solid var(--theme-color) !important;
  /* background: var(--theme-color) !important; */
  /* color: var(--white-color) !important; */
  color: var(--theme-color) !important;
  background: transparent !important;
}

.theme-btn-bg {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
}

.theme-btn-bg:hover {
  color: var(--theme-color) !important;
  background: transparent !important;
}

.home-subscribe-section {
  position: relative;
  margin-bottom: -17rem;
  /* margin-bottom: -12rem; */
  /* height: 100%; */
  /* height: 70vh; */
}

.home-subscribe-section .content {
  position: absolute;
  top: -4%;

  width: 100%;
  transform: translateY(50%);
  text-align: center;
  padding: 1rem;
}

.home-subscribe-section img {
  width: 100%;
  object-position: center -180px;
  height: 100vh;
  object-fit: cover;
}

.home-subscribe-section .content h2 {
  font-family: "headingbold" !important;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.home-subscribe-section .content p {
  margin: 0 auto;
  margin-bottom: 2rem !important;
  width: 50%;
}
.home-subscribe-section .content a:hover {
  border: 2px solid var(--theme-color) !important;
  background: transparent !important;
  color: var(--theme-color) !important;
}

.and-font {
  color: var(--font-dark);
  font-family: "bodyfontbold" !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sensitive-content {
  user-select: none;
  pointer-events: none;
}

.catselect .form-control {
  padding: 0 !important;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
}

.ant-card-cover {
  height: 200px;
  overflow: hidden;
}
@media (max-width: 1800px) {
  .home-subscribe-section img {
    height: 84vh;
  }
}

@media (max-width: 1600px) {
  .home-subscribe-section img {
    height: 100vh;
  }
}

@media (max-width: 1400px) {
  .home-subscribe-section .content p {
    width: 62%;
  }
  .home-subscribe-section img {
    height: 90vh;
  }
}

@media (max-width: 1200px) {
  .home-subscribe-section .content {
    top: -9%;
  }
  .home-subscribe-section img {
    height: 100vh;
  }
}

@media (max-width: 992px) {
  .section-spacing {
    padding: 50px 0;
  }

  .home-subscribe-section .content h2 {
    font-size: 1.5rem;
  }

  .home-subscribe-section {
    /* height: 550px; */
    /* height: 82vh; */
    margin-top: 2rem;
    margin-bottom: -19rem;
    /* margin-bottom: -13rem; */
  }

  .home-subscribe-section img {
    height: 90vh;
    object-fit: cover;
  }

  .home-subscribe-section .content {
    top: -10%;
  }

  .home-subscribe-section .content p {
    width: 69%;
    font-size: 0.9rem;
  }

  .theme-btn {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .home-subscribe-section .content {
    /* position: absolute; */
    top: -8%;

    /* width: 100%; */
    transform: translateY(50%);
    /* text-align: center; */
    /* padding: 1rem; */
  }
  .home-subscribe-section .content h2 {
    font-size: 1.2rem;
  }
  .home-subscribe-section .content p {
    /* width: 100%; */
    width: 69%;
    font-size: 0.6rem;
  }

  .home-subscribe-section img {
    height: 83vh;
    /* object-fit: cover; */
  }
}
