.top-slider-section img {
    border-radius: 20px;
    width: 100%;
    height: 500px;
}

.top-slider-section .content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
    text-align: center;
}

.top-slider-section .content h2 {
    text-transform: uppercase;
    font-family: "headingbold" !important;
    font-size: 3rem;
}