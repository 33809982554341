.careers-page .category-tab {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;
}

.careers-page .category-tab p {
    font-size: 1.4rem;
    font-weight: bolder;
    color: var(--theme-color);
}

.careers-page .category-tab ul {
    display: flex;
}

.careers-page .category-tab ul li {
    font-weight: bolder;
    cursor: pointer;
    margin-left: 1.5rem;
}

.careers-page .main-heading h2 {
    text-align: center;
    font-size: 3rem;
    font-family: "headingbold" !important;
    margin-bottom: 3rem;
}



.careers-page .careers-category ul {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: bolder;
    margin-bottom: 2rem !important;
}

.careers-page .careers-category ul p {
    cursor: pointer;
}









.careers-page .ant-collapse-borderless {
    background: transparent !important;
    border-radius: 0 !important;
}

.careers-page .ant-collapse-item,
.careers-page .ant-collapse-item:last-child {
    border: none !important;
    background: var(--main-color-one);
    margin-bottom: 1rem;
    padding: 10px 3px !important;
    border-radius: 20px;
}

.careers-page .ant-collapse-item:last-child {
    margin-bottom: 0;
}

.careers-page .careers-card {
    display: flex;
    width: 100%;
}

.careers-page .careers-card .content h2 {
    font-family: "bodyfontbold" !important;
    text-transform: uppercase;
    /* font-weight: bolder !important; */
}

.careers-page .careers-card .content {
    /* display: flex;
    justify-content: space-between; */
    width: 100%;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 6rem;
}



.careers-page .careers-card .image {
    margin-right: 1.3rem;
    width: 40%;
    height: 200px !important;
}

/* .careers-page .careers-card .image .d-flex{
    display: none !important;
} */



.careers-page .careers-card .image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    /* margin-bottom: 1rem; */
}

.careers-page .ant-collapse-item {
    position: relative;
}

.careers-page .ant-collapse-expand-icon {
    position: absolute;
    bottom: 10px;
    right: 1rem;
    width: 40px;
    height: 40px !important;
    background: var(--theme-color);
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0 !important;
    color: var(--white-color);
}

.careers-page .ant-collapse-expand-icon .ant-collapse-arrow {
    font-size: 1rem !important;
}

.careers-page .bottom-content {
    margin-top: -15px;
    padding-right: 6rem;
    /* transform: translateY(-100px); */
    width: 68.8% !important;
    margin-left: auto;
}

.careers-page .bottom-content .content {}

@media (max-width:992px) {
    .careers-page .category-tab {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;
    }

    .careers-page .category-tab ul {
        /* overflow-x: auto; */
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }

    .careers-page .category-tab ul li {
        margin-left: 0;
        margin-bottom: .7rem;
        /* display: inline-block;
        width: fit-content; */
        /* min-width: 100px; */
    }
    .careers-page .main-heading h2{
        font-size: 2rem;
    }
    .careers-page .careers-category{
        overflow-x: auto;
    }
    .careers-page .careers-category ul{
        width: 125%;
    }
    .careers-page .careers-card{
        flex-direction: column;
    }
    .careers-page .careers-card .image{
        width: 100%;
        margin-right: 0;
        margin-bottom: 2rem;
    }
    .careers-page .careers-card .content{
        padding: 0;
    }
    .careers-page .careers-card .content p{
        padding-right: 3rem;
    }
    .careers-page .bottom-content{
        margin: 0;
        padding: 0;
        width: 100% !important;
    }
}