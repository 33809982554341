.flip-book .flip-book-wrapper{
  width: 77% !important;
  margin-bottom: -5rem;

}
.full-screen-container {
  /* position: relative; */
  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color:var(--theme-color); */
  /* margin-top: -4rem; */
  /* margin-left: 1rem; */
}
.full-screen-container > div {
  width: 100% !important;
  min-width: 100% !important;
}

.page-pair {
  display: flex !important;
  justify-content: space-between;
  width: 100% !important;
  height: 92% !important;
  left: 0 !important;
  top: 0 !important;
  padding: 0 2rem;
  /* background-color: pink;  */
  /* margin-bottom:-5rem ; */
}

.left-page,
.right-page {
  width: 50% !important;
  height: 100%;
  text-align: center;
  border: 1px solid #e3e3e3;
}

.left-page img,
.right-page img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.pagination {
  /* position: absolute;
    bottom: 20px; */
  margin-top: -3.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  /* background-color: yellow; */
  z-index: 5;
}

.theme-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.theme-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.theme-btn:hover:not(:disabled) {
  background-color: var(--theme-color);
}

/* this css code genrate through chatgpt */

/* .page-pair {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.left-page, .right-page {
    width: 50%; /* Each page takes half of the available width */
/* height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */
/* 
img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
} */
/* 
.page-placeholder {
    width: 100%;
    height: 100%;
    background-color: #eaeaea;
} */

/* end here chat gpt */

@media (max-width: 992px) {
  /* .full-screen-container > div{
        min-height: 400px !important;
    }    */
}
