.news-detail-page .newsletter-section{
    margin-top: 0 !important;
}
.news-detail-section img {
    border-radius: 20px;
    width: 100%;
    /* height: 400px; */
    height: 676px;
    object-fit: cover;
    /* margin-bottom: 4rem; */
}

.news-detail-section h2 {
    text-transform: uppercase;
    font-family: "headingbold" !important;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--black-color);
    
}

.news-detail-section .box {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.news-detail-section .box ul {
    display: flex;
    gap: 1rem;
}
.news-detail-section .box ul svg{
    transition: .3s;
}
.news-detail-section .box ul svg:hover{
    color: var(--theme-color);
}
.news-detail-section .scroll-section{
    overflow-y: scroll;
    max-height: 100vh;
}

.news-detail-section h1 {
    font-family: "headingbold" !important;
    margin-bottom: 2rem;
}

.news-detail-section p {
    margin-bottom: 1rem !important;
}

.news-detail-section .source{
    display: flex;
    justify-self: flex-end;
    margin-top: 0.5rem;
}
.news-detail-section .top-section{
    margin-bottom: 4rem;
}
@media (max-width:992px) {
    .news-detail-section h1{
        margin-top: 2rem;
    }
    .news-detail-section .box {
        margin-bottom: 1rem;
    }
}
@media(max-width:576px){
    .news-detail-section img {
        height: 500px;
    }
       
.news-detail-section .top-section{
    margin-bottom: 2rem;
}

}

@media(max-width:450px){
    .news-detail-section img {
        height: 350px;
    }
  

}