.latest-article-section{
  padding-top: 80px;
  padding-bottom: 80px;
}
.latest-article-section .row{
  background-color: lightgray;
}
.latest-article-section .left-section{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.latest-article-section .left-section .top-section h2 {
    font-size: 2rem;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
 
  }
  .latest-article-section .left-section .bottom-section{
    margin-top: 0.6rem;
  }

  .latest-article-section .left-section .bottom-section  p{
    font-size: 1.2rem;
        overflow: hidden;
        font-family: Helvetica;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; 
  }

  .latest-article-section .left-section .bottom-section .read-more-btn{
    padding: 8px 30px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color) !important;
    border-radius: 30px;
    font-size: 1.2rem;
    transition: .3s all;    
    margin-top: 1rem;
    font-weight: bolder;
    display: inline-block;
    width: fit-content;

  }
  
  .latest-article-section .left-section .bottom-section .read-more-btn:hover{
  background: var(--theme-color);
  color: var(--white-color) !important;
  }

/*  
  .latest-article-section .left-section .bottom-section .read-more-btn:hover{
    text-decoration: underline !important;
  } */

  .latest-article-section .right-section{
    padding-left: 0px;
    padding-right: 0px;
  }
  .latest-article-section .right-section img{
      /* border-radius: 10px; */
      height: 676px;
      object-fit:  cover;
      width: 100%;
      /* margin-top: 1rem; */
      object-position: top;
  }






  @media (max-width: 992px) {
    .latest-article-section h2 {
      margin-top: 3rem;
      font-size: 1.5rem;
      /* margin-bottom: 2rem !important; */
    }
  
    .latest-article-section p {
      margin-top: 1rem;
    }
  }
  
  @media(max-width:768px){
  .latest-article-section .right-section img {
      height: 450px;
  }
  }

  @media(max-width:576px){
    .latest-article-section .right-section img {
      height: 300px;
      
    }
    
.latest-article-section .left-section .top-section h2 {
  font-size: 1.3rem;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: none;
  -webkit-box-orient: vertical;

}
.latest-article-section .left-section .bottom-section p {
  font-size: 1rem;
}

  }