.home-header {
    background-color: var(--main-color-one);
    padding-bottom: 1rem;
    padding-bottom: 2rem;
    /* margin-top: 2rem; */
}

.home-header .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* margin-top: 1rem; */
}

.home-header .content .left-content {
    display: flex;
    flex-direction: column;
    margin-left: -4px;
    width: 90%;
    
}

.home-header .content .left-content .name {
    font-size: 6.7rem;
    letter-spacing: 5px;
    text-transform: uppercase;
    /* font-weight: bolder; */
    line-height: 1.1;
    font-family: "headingbold" !important;
    color: #3E3D3D !important;

}

.home-header .content .left-content .name span {
    font-family: "bodyfontbold" !important;
}

.home-header .content .logo {
    width: 130px;
    margin-right: 1.6rem;
    margin-bottom: 3px;
}

.home-header .content .logo img {
    border-radius: 5px;
}

.home-header .content .left-content h1 {
    font-size: 2.5rem;
    font-weight: 600 !important;
    opacity: .7;
    /* margin-left: 2.5rem; */
    margin-left: 1.8rem;
    font-family: "bodyfont" !important;
    letter-spacing: 1.5px;
}

.home-header .content .right-sm-links ul li a {
    background: var(--main-color-two);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    transition: .3s;
}
.home-header .content .right-sm-links ul li a:hover{
    background: var(--theme-color);
}
.home-header .content .right-sm-links ul li a:hover svg{
    color: var(--white-color);
}

@media (max-width:1400px){
    .home-header .content{
        align-items: center;            
    }
    .home-header .content .left-content .name {        
        font-size: 5.8rem;      
    }
    .home-header .content .left-content h1 {       
        font-size: 2.1rem;        
        margin-left: 1rem;     
    } 

}
 
 
@media(max-width:1200px){
    .home-header .content{
        align-items: center;            
    }
    .home-header .content .left-content .name {        
        font-size: 4.5rem;      
    }
    .home-header .content .left-content h1 {       
        font-size: 1.7rem;        
        margin-left: 1rem;     
    } 
}

 

@media (max-width:992px) {
    .home-header .content{
        align-items: center;            
    }
    .home-header .content .left-content{
        margin-left: 4px;
    }    
    .home-header .content .left-content .name {        
        font-size: 3.7rem;      
    }
    .home-header .content .left-content h1 {       
        font-size: 1.3rem;        
        margin-left: 1rem;     
    }
    .home-header .content .logo {        
        margin-right: 1rem;
        width: 9vw;    
    } 
    .home-header .content .right-sm-links ul li a {
        /* width: 20px;
        height: 20px; */
    }
}

@media (max-width:768px){
    .home-header {
        /* padding-top: 1rem; */
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .home-header .content {
        flex-direction: column;
        align-items: flex-start;
        padding: -2rem;
        
    }
    .home-header .content .left-content {
        width: 100%;
    }
    .home-header .content .left-content{
        /* margin-left: -4.3rem; */
    }   
    .home-header .content .left-content .name {
        /* font-size: 3rem; */
        font-size: 9vw;
    }
    .home-header .content .left-content h1 {
        /* font-size: 1rem; */
        font-size: 3.1vw;
        margin-left: 1rem;  
    }
    .home-header .content .logo {
        margin-right: 1rem;
        /* width: 9vw;   */
        width: 10vw;
        /* margin-left: 15px; */
    }  
    .home-header .content .logo img {
        border-radius: 3px;
    }
 
    .home-header .content .right-sm-links ul {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-left: 0.3rem;
    }
    .home-header .content .right-sm-links{
        align-self: flex-start;
    }
} 

@media (max-width:576px){
 

    .home-header .content {
        align-items: flex-start;
    }
    .home-header .content .left-content .name {
        /* font-size: 2rem; */
        font-size: 8.3vw;
    }
    .home-header .content .left-content h1 {
        /* font-size: 0.6rem; */
        font-size:2.9vw;
        margin-left: 0.8rem;  
        margin-bottom: 0.3rem;   
    }
    .home-header .content .logo {
        margin-right: 0.8rem;
       
    }   
    .home-header .content .logo img {
        border-radius: 3px;
    }

   
}
 
@media(max-width:450px){
    .home-header .content .left-content .name {
        /* font-size: 1.4rem; */
        font-size: 7.7vw;
    }
    .home-header .content .left-content h1 {
        /* font-size: 0.5rem; */
        font-size: 2.7vw;
        margin-left: 0.5rem; 
        margin-bottom: 0.2rem;    
    }
}
 
@media (max-width: 374.98px) {
    .home-header .content .left-content .name {
            /* font-size: 1.1rem; */
            font-size: 6.9vw;
        }
    .home-header .content .left-content h1 {
        /* font-size: 0.4rem; */
        font-size: 2.5vw;
        margin-left: 0.5rem;
        margin-bottom: 0.1rem;
    }
}