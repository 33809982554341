:root{
  --bs-border-radius-lg: 1.5rem !important;
}
.modal{
  --bs-modal-width: 1000px !important;
 }
 .modal-header {
  border-bottom: 0rem !important;
  padding-left: 1.7rem !important;
  padding-right: 1.7rem !important;
 }
 .login-modal .modal-title{
  display: flex;
  gap: 44rem;
 }
/* 
 .login-modal .modal-title .language-dropdown{
 border-radius: 30px;
  font-size: 1rem;
  padding: 0px 20px;
 }  */

 .login-body .leftImg {
  width: 100%;
  height:100%;
 }
 .login-body .leftImg img{
  width: 100%;
  height: 100%;
  border-radius: 20px;
 }
 .login-body  .login-form {
  max-width: none !important;
  padding: 23px !important;
 }
 .login-body .login-form p{
  cursor: pointer;
  text-align: center;
  font-family: "bodyfont" !important;
  margin-bottom: 0.5rem !important;
 }
 .login-body .login-form h6{
  text-align: right;
  color: var(--theme-color);
  cursor: pointer;
 }
 .modal-title img{
  width: 100px;
 }
 .login-body .login-form p span{
  color: var(--theme-color); 
 }
 .login-body .login-form h2{
  font-family: "headingbold" !important; 
  margin-bottom: 5px !important;
 }
 .login-body .login-form h6{
  /* margin-bottom:7.59rem; */
 }
 .login-body .login-form .login-btn {
  font-family: "bodyfont" !important;
 }
.btn-close { 
 /* --bs-btn-close-focus-shadow: 0 0 0 0.25rem var(--theme-color)!important; */
 --bs-btn-close-focus-shadow:none
 --bs-btn-close-color:white !important;
}
.login-body .login-form .social-icons{
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
gap: 0.5rem;
} 
.login-form .login-with-google{
  padding: 12px 20px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #ddd !important;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  
}
.login-form .login-with-google svg{
  margin-left: 5px;
}
.login-form .content{
  margin: 3.2px auto;
  width: 200px;
}
.login-form .content .or{
  text-align: center;
  font-size: 20px;
  background:
    linear-gradient(#000 0 0) left,
    linear-gradient(#000 0 0) right;
  background-size: 40% 1px;
  background-repeat: no-repeat;
}
  