

.new-articles-section .left-section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  /* background-color: pink; */
}

.new-articles-section .left-section .top-section{
  /* background-color: yellow; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  text-align: center;
}
.new-articles-section .left-section .bottom-section p{
  text-align: center;
  font-size: 1.2rem;
  overflow: hidden;
  font-family: Helvetica;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;


}

.new-articles-section h2 {
  font-size: 2.7rem;
  line-height: 1.4;
  overflow: hidden;
  font-family: Helvetica;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}
  .new-articles-section img{
    border-radius: 10px;
    height: 676px;
    object-fit:  cover;
    width: 100%;
    margin-top: 1rem;
    object-position: top;
}
.new-articles-section .source{
  display: flex;
  justify-self: flex-end;
  margin-top: 0.5rem;

}


@media (max-width:1500px){
  .new-articles-section h2 {
    font-size: 2.4rem;
   
  }

}

@media (max-width:1400px){
  .new-articles-section h2 {
    font-size: 2rem;
    
  }

}

@media (max-width: 992px) {
  .new-articles-section h2 {
    /* margin-top: 3rem; */
    font-size: 1.5rem;
    /* margin-bottom: 2rem !important; */
  }

  .new-articles-section p {
    margin-top: 1rem;
  }
}

@media (max-width:768px){
  .new-articles-section h2 {
    font-size: 1.3rem;
    line-height: 1.4;
  }

}

@media (max-width:576px){
  .new-articles-section h2 {
    font-size: 1.3rem;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: vertical;
}
.new-articles-section .left-section .bottom-section p {
  font-size: 1rem;
}
  
  .new-articles-section img {
    height: 300px;
  }

}
