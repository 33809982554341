.current-affairs-section .left-box,
.current-affairs-section .center-box .top-section,
.current-affairs-section .center-box .bottom-vido-section,
.current-affairs-section .right-box .top-section {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    cursor: pointer;
}

.current-affairs-section .main-headin-box p {
    width: 50%;
}

.current-affairs-section img {
    
    /* height: 100%; */
}

/* .current-affairs-section .left-box img, */
/* .current-affairs-section .center-box .top-section img,
.current-affairs-section .center-box .bottom-vido-section img,
.current-affairs-section .right-box .top-section img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    } */
.current-affairs-section .center-box .top-section img,
.current-affairs-section .left-box img {
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}

.current-affairs-section .left-box .bottom-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--theme-color-gradiant);
    padding: 1rem;
    color: var(--white-color);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
}

.current-affairs-section .right-box .top-section .content span,
.current-affairs-section .left-box .bottom-text span {
    font-size: .8rem;
    color: var(--white-color);
}

.current-affairs-section .left-box .bottom-text p {
    /* font-size: 1.5rem; */
    font-weight: bolder;
}

.current-affairs-section .center-box .top-section img {
    aspect-ratio: 1/1;
    width: 100%;
    height: 250px;
    /* height: 35vh; */
}

/* .current-affairs-section .center-box .top-section::before{
    content: "";
    position: absolute;
    bottom: 3.5rem;
    left: 0;
    width: 100%;
    height: 2px;
    background: #636669;
} */
.current-affairs-section .center-box .top-section p {
    /* position: absolute;
    top: 2rem; */
    /* left: 0; */
    /* margin-top: 2rem; */
    /* padding: 10px; */
    /* font-size: 1.3rem; */
    /* padding-right: 5px; */



    /* width: 70%; */
    /* font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
 
}

.current-affairs-section .bottom-text p,
.current-affairs-section .center-box p, 
.current-affairs-section .right-box .top-section p{
    font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
}


.current-affairs-section .center-box .top-section .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--theme-color-gradiant);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--white-color);
}

.current-affairs-section .center-box .top-section .content span {
    font-size: .7rem;
}

.current-affairs-section .center-box .top-section .btn-box {
    position: absolute;
    top: 0;
    right: 0;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.current-affairs-section .center-box .top-section .btn-box img.bg-shape {
    width: 100px;
    object-fit: contain;
    height: auto;
    margin-top: -6px;
    transform: translateX(1.5px);
}

.current-affairs-section .center-box .top-section .btn-box a {
    position: absolute;
    top: 0;
    right: 8px;
    /* transform: rotate(-30deg); */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color);
    padding: 3px;
    border-radius: 50%;
}

.current-affairs-section .center-box .top-section .btn-box a img {
    width: 55px;
    padding: 8px;
    height: auto;
}

.current-affairs-section .center-box .bottom-vido-section {
    background-color: var(--main-color-two);
    border-radius: 20px;
    overflow: hidden;
    margin-top: 1.5rem;
    position: relative;
}

.current-affairs-section .center-box .bottom-vido-section img {
    width: 100%;
    height: 250px;
    /* height: 35vh; */
}

.current-affairs-section .center-box .bottom-vido-section svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--theme-color);
    font-size: 5rem;
    cursor: pointer;
}



.current-affairs-section .center-box .bottom-vido-section .bottom-video-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--theme-color-gradiant);
    padding: 1rem;
    color: var(--white-color);
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
}


.current-affairs-section .center-box .bottom-vido-section .bottom-video-text span{
    font-size: .8rem;
    color: var(--white-color);
}

.current-affairs-section .center-box .bottom-vido-section .bottom-video-text p {
    /* font-size: 1.5rem; */
    font-weight: bolder;
}




.current-affairs-section .right-box {
    height: 100%;
}

.current-affairs-section .right-box .top-section {
    /* height: 76%; */
    /* height: 75.8%;  */
    /* height: 65%; */
    height: 75%;
     
}

.current-affairs-section .right-box .top-section img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    /* object-position: left; */
    object-position: center;
}

.current-affairs-section .right-box .top-section .content {
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    color: var(--white-color);
    background: var(--theme-color-gradiant);
    font-weight: bolder;
}

.current-affairs-section .right-box .view-all-btn {
    /* height: 25%; */
    padding: 13.5px 20px;
    background: var(--theme-color);
    border-radius: 30px;
    margin-top: 1.5rem;
    font-size: 1.3rem;
    font-weight: bolder;
    letter-spacing: 3px;
    /* text-align: center; */
}

.current-affairs-section .right-box .view-all-btn a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color) !important;
    /* gap: 10px; */
}

.current-affairs-section .right-box .view-all-btn a svg {
    font-size: 5rem;
    margin-left: 1.5rem;
}


/* @media (max-width:992px) {
    .current-affairs-section .left-box img {
        width: 100%;
    }
    .current-affairs-section .main-headin-box {
        flex-direction: column;
        margin-top: 2rem;
    }
    .current-affairs-section .main-headin-box p {
        width: 100%;
        margin-top: 1rem;
    }
    .current-affairs-section .center-box .bottom-vido-section{
        margin-top: 3rem;
    }
    .current-affairs-section .center-box .top-section{
        height: 100%;
    }
    .current-affairs-section .center-box .top-section img{
       width: 100%;        
    }
    .current-affairs-section .center-box .bottom-vido-section img {
        width: 100%;        
    }
    .current-affairs-section .center-box .top-section .btn-box img.bg-shape{
        width: 80px;
    }
    .current-affairs-section .center-box .top-section .btn-box a img{
        width: 40px;
    }
    .current-affairs-section .right-box .view-all-btn{
        font-size: 1rem;
        padding: 5px 15px;
        margin-top: 2rem;
    }
    .current-affairs-section .right-box .view-all-btn a svg {
        font-size: 4rem;
    }
} */


/*  
@media(min-width:1920px){
    .current-affairs-section .right-box .top-section {
        height: 86%;
    }
}  */

 
/* 
@media(min-width:1800px){
    .current-affairs-section .right-box .top-section {
        height: 85%;
    }
}  */

/* 
@media(min-width:1600px){
    .current-affairs-section .right-box .top-section {
        height: 81.5%;
    }
} */

/*  
@media(max-width:1400px){
    .current-affairs-section .right-box .top-section {
        height: 80.9%;
    }
}  
  

@media(max-width:1200px){
    .current-affairs-section .right-box .top-section {
        height: 77.5%;
    }
} */

@media(max-width:1800px){
    .current-affairs-section .center-box .top-section img {  
        height: 350px;
    }
    .current-affairs-section .center-box .bottom-vido-section img {
        height: 350px;

    }
    .current-affairs-section .right-box .top-section {
        height: 81.8%;
    }

}
@media(max-width:1600px){
    .current-affairs-section .right-box .top-section {
        height: 75%;
    }
    .current-affairs-section .center-box .top-section img {  
        height: 250px;
    }
    .current-affairs-section .center-box .bottom-vido-section img {
        height: 250px;

    }

}

@media(max-width:992px){
 
.current-affairs-section .bottom-text p,
.current-affairs-section .center-box p, 
.current-affairs-section .right-box .top-section p{
    font-weight: bolder;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; 
}
 
.current-affairs-section .right-box .view-all-btn a svg {
    font-size: 5rem;
    margin-left: 1.5rem;
}
.current-affairs-section .right-box .top-section {
    /* height: 73%; */
    height: 75%;
}


 
}

@media (max-width:767.98px) {
    .current-affairs-section .main-headin-box {
        flex-direction: column;
        margin-top: 2rem;
    }
    .current-affairs-section .main-headin-box p {
        width: 100%;
        margin-top: 1rem;

    }

    .current-affairs-section .center-box .top-section img { 
        height: 175px;
    }
    
    .current-affairs-section .center-box .bottom-vido-section img {
        height: 175px;
    }

    /* .current-affairs-section .center-box .top-section{
        height: 85%;

    } */
/*      
    .current-affairs-section .center-box .bottom-vido-section{
        margin-top: 3rem;
    } */


    .current-affairs-section .right-box .top-section img {
        aspect-ratio: 1/0.5;
    }
    .current-affairs-section .center-box .top-section .btn-box img.bg-shape{
        width: 80px;
    }
    .current-affairs-section .center-box .top-section .btn-box a img{
        width: 40px;
    }
    .current-affairs-section .right-box .view-all-btn{
        font-size: 1rem;
        padding: 5px 15px;
        /* margin-top: 2rem; */
        margin-top: 1.5rem;
        /* width:35%; */
        width: 50%;
        transform: translateX(50%);
    }
    .current-affairs-section .right-box .view-all-btn a svg {
        font-size: 2.5rem;
    }
}


@media(max-width:576px){

.current-affairs-section .center-box .top-section img{
    /* height: 311px; */
    /* width: 100%; */
    
} 
.current-affairs-section .center-box .bottom-vido-section img {
    /* width: 100%; */
    /* height: 311px; */
} 
.current-affairs-section .right-box .view-all-btn{
    /* font-size: 0.7rem; */
 
}
.current-affairs-section .right-box .view-all-btn a svg {
    /* font-size: 1.5rem; */
    margin-left: 0.5rem;
}
.current-affairs-section .right-box .view-all-btn {
    width: 100%;
    transform: translate(0%);

}

}

@media(max-width:450px){
    .current-affairs-section .right-box .view-all-btn {
        font-size: 0.8rem;
    }
    .current-affairs-section .right-box .view-all-btn a svg {
        font-size: 2rem;
    }
} 

@media(max-width:375px){
    .current-affairs-section .right-box .view-all-btn {
        font-size: 0.7rem;
    }
    
    .current-affairs-section .center-box .top-section img { 
        height: 160px;
    }
    
    .current-affairs-section .center-box .bottom-vido-section img {
        height: 160px;
    }

}