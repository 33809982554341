.contact-us-mission-section {
    position: relative;
}

.contact-us-mission-section .main-heading h2,
.contact-us-mission-section .left-section .main-heading h4 {
    font-family: "headingbold" !important;
    letter-spacing: 2px;
}

.contact-us-mission-section .left-section::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    border-radius: 20px;
    background: var(--main-color-one);
    z-index: -1;
}

.contact-us-mission-section b {
    font-family: "bodyfontbold" !important;
    text-transform: capitalize;
}

.contact-us-mission-section .left-section .left-content {
    width: 60%;
    padding-right: 2rem;
}

.contact-us-mission-section .left-section .left-content p {
    font-size: .8rem;
}

.contact-us-mission-section .left-section .right-img {
    width: 40%;
    /* height: 500px; */
}

.contact-us-mission-section .right-section {
    font-size: 1.2rem;
}

.contact-us-mission-section .right-section p {
    line-height: 1.5;
}

.contact-us-mission-section a {
    padding: 10px 20px;
    background: var(--main-color-one);
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: bold;
}

@media (max-width:992px) {
    .contact-us-mission-section .left-section {
        position: relative;
        margin-bottom: 3rem;
    }

    .contact-us-mission-section .left-section>.d-flex {
    
        flex-direction: column;
    }

    .contact-us-mission-section .left-section .right-img,
    .contact-us-mission-section .left-section::before,
    .contact-us-mission-section .left-section .left-content {
        width: 100%;
    }
    .contact-us-mission-section .left-section .left-content {
        padding-top: 50px;
    }

    .contact-us-mission-section .left-section::before {
        height: 77%;
    }

    .contact-us-mission-section .left-section .right-img {
        margin-top: 3rem;
        /* width: 80%; */
        width: 38%;
    }
}
@media(max-width:768px){
    .contact-us-mission-section .left-section::before {
        height: 79%;
    }
}

@media(max-width:576px){
    .contact-us-mission-section .left-section::before {
        /* height: 85%; */
        height: 81%;
    }
    .contact-us-mission-section .left-section .right-img {
        width: 50%;
    }
    contact-us-mission-section .right-section p {
        font-size: 1rem;
    }
}

@media(max-width:449.98px){
    .contact-us-mission-section .left-section::before {
        /* height: 88%; */
        height: 85%;
    }
    .contact-us-mission-section .main-heading h2{
        font-size: 1.3rem;
    }
    .contact-us-mission-section .left-section .main-heading h4 {
        
        font-size: 1rem;
    }
  
}
@media(max-width:374.98px){
    .contact-us-mission-section .left-section::before {
        /* height: 91%; */
        height: 88%;
    }
}