.new-article-section h1 {
    font-size: 3rem;
    font-family: "headingbold" !important;
    padding-right: 2rem;
    text-transform: uppercase;
}

.new-article-section img {
    width: 100%;
    border-radius: 20px;
    margin-top: 2rem;
    height: 800px;
}