.badges-section .heading {
  margin-bottom: 2rem;
  font-family: "headingbold" !important;
  text-align: center;
  color: var(--font-dark);
}

.badges-section .badge-category ul {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: bolder;
  margin-bottom: 2rem !important;
}
.badges-section .badge-category ul p {
  cursor: pointer;
}

.badges-section .badge-card-box {
  padding: 2rem;
  border: 1px solid var(--main-color-one);
  border-radius: 20px;
}

.badges-section .badge-card-box .top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.badges-section .badge-card-box .top-section .top-card {
  padding: 1rem;
  background: var(--main-color-one);
  width: 22%;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
}

.badges-section .badge-card-box .top-section .top-card h3 {
  font-family: "headingbold" !important;
  font-weight: bolder;
  margin-bottom: 0;
  color: var(--font-dark);
}

.badges-section .badge-card-box .badge-card {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--main-color-one);
  margin-bottom: 1rem;
}
.badges-section .badge-card-box .badge-card:last-child {
  margin-bottom: 0;
}

.badges-section .badge-card-box .badge-card div {
  width: 25%;
}

.badges-section .badge-card-box .badge-card .image {
  /* width: 25%; */
  width: 16%;
  overflow: hidden;
  margin-right: 1rem;
  border-radius: 15px;
  text-align: center;
}

.badges-section .badge-card-box .badge-card .image img {
  /* width: 100%; */
  /* aspect-ratio: 1/0.5; */
  aspect-ratio: 1/1;
}

.badges-section .badge-card-box .badge-card h4 {
  font-family: "headingbold" !important;
  text-transform: uppercase;
  /* text-align: center; */
  padding-left: 1rem;
  color: var(--font-dark);
}

.badges-section .badge-card-box .badge-card p {
  padding-left: 1rem;
  padding-right: 5px;
  font-size: 0.9rem;
  /* text-align: justify; */
  padding-left: 2.5rem;
  line-height: 1.4;
}
@media (max-width:992px) {
  .badges-section .heading{
    font-size: 1.5rem;
  }
  .badges-section .badge-category ul{
    /* overflow-x: auto; */
    flex-direction: column;
    gap: 1rem;
  }
  .badges-section .badge-card-box .badge-card {
    /* width: 200%; */
    width: 190vw;

  }

  /* .badges-section .badge-card-box .badge-card .image{
    margin-right:15vw;
  } */

  /* .badges-section .badge-category ul li{
    min-width: 220px;
  } */
  .badges-section .badge-card-box {
    overflow-x: auto;
  }
  .badges-section .badge-card-box .top-section{
    /* width: 200%; */
    width: 190vw;
  }
}

@media(max-width:576px){
  .badges-section .heading {
    font-size: 1.3rem;
  }
  
.badges-section .badge-category ul p {
  font-size: 1.13rem;
}
}