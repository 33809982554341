.service-section .heading-box a {
    padding: 8px 30px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color) !important;
    border-radius: 30px;
    font-size: 1.2rem;
    transition: .3s all;
}

.service-section .heading-box a svg {
    font-size: 1.5rem;
    margin-left: 10px;
}

.service-section .heading-box a:hover {
    background: var(--theme-color);
    color: var(--white-color) !important;
}

.service-section .service-card {
    display: flex;
    align-items: center;
    background: var(--theme-color);
    border-radius: 20px;
    padding: 1rem;
    padding-right: 2rem;
}

.service-section .service-card .image {
    border-radius: 15px;
    overflow: hidden;
    width:15%;    
}

.service-section .service-card .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    /* aspect-ratio: 1/0.6; */
}

.service-section .service-card .content {
    width: 85%; 
    display: flex;
    justify-content: space-between;
    color: var(--white-color);
    /* align-items: center; */
}

.service-section .service-card .content .text {
    padding: 0 2rem;
}

.service-section .service-card .content .name {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 8px !important;
    font-family: "bodyfontbold" !important;
}

.service-section .service-card .content .des {
    /* font-size: 1.1rem; */
    font-size: 1.2vw;
    /* width: 80%; */
    width: 100%;
    letter-spacing: 0px;
    

}

.service-section .service-card .content .arrow-btn {
    align-self: center;
}

.service-section .service-card .content .arrow-btn a {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    /* padding: 10px; */
    border-radius: 50%;
    background: var(--white-color);
    border: 2px solid var(--white-color);
    transform: rotate(-30deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all;
}

.service-section .service-card .content .arrow-btn a svg {
    color: var(--theme-color);
}

.service-section .service-card .content .arrow-btn a:hover {
    background: var(--theme-color);
}

.service-section .service-card .content .arrow-btn a:hover svg {
    color: var(--white-color);
}




@media(max-width:1400px){

}







@media(max-width:1200px){


}

  

@media (max-width:992px) {
    .service-section .heading-box {
        align-items: center;
    }

    .service-section .heading-box a {
        font-size: 1rem;
        padding: 5px 15px;
    }
    .service-section .service-card {
        /* flex-direction: column; */
        display: flex;
         flex-direction: row; 
         align-items: center;
     } 

    
    .service-section .service-card .content .text{
        padding: 0 2rem;
    }

/*     
    .service-section .service-card .image{
        width: 20%;        
    }
    .service-section .service-card .image img{
        width: 100%;
       
        object-fit: contain;
    }
    .service-section .service-card .content{
        width: 80%;
    } */



    .service-section .service-card {
        padding: 1rem;
    }
    .service-section .service-card .content .arrow-btn{
        align-self: flex-end;
    }
    /* .service-section .service-card .content .name{
        font-size: 1.3rem;
    } */

    .service-section .service-card .content .des {
        /* font-size: 0.9rem; */
        font-size: 1.5vw;
    }
    /* .service-section .service-card .content .arrow-btn a{
        width: 30px;
        height: 30px;
        font-size: 1rem;
    } */
}


@media(max-width:768px){
    .service-section .service-card .content .des { 
        font-size: 2vw;
    }
    .service-section .service-card .image { 
    width:24%;     
      }
    .service-section .service-card .content .text {
    padding: 0 1.5rem;
        }

}

@media(max-width:576px){
    .service-section .service-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .service-section .service-card .image {
        width: 30%;
    }
    
.service-section .service-card .content {
    width: 100%; 
}

.service-section .service-card .content .text {
    /* padding: 0 2rem; */
    padding-left: 0rem;
    padding-right: 0.5rem;
    
}
.service-section .service-card .content .des {
    font-size: 2.5vw;
}


}

@media (max-width:450px){
    .service-section .service-card .content .des {
        font-size: 3vw;
    }
}

@media(max-width:375px) {
    .service-section .service-card .content .name {
        font-size: 1.2rem;
        margin-bottom: 4px;
    }
}



/* 

@media (max-width:767.8px) {
    .service-section .service-card {
        flex-direction: column;
        display: flex;
         align-items: center;
     } 
    .service-section .service-card .content {
        margin-top: 0.2rem;
    }
    .service-section .service-card .image{   
        width: 50%;
    }
    
    .service-section .service-card .content{
        width: 100%;
    }  
    .service-section .service-card {
        padding: 1rem;
    }
    .service-section .service-card .content .arrow-btn{
        align-self: flex-end;
    }
    .service-section .service-card .content .text{
        padding-left: 0rem;
        padding-right: 0.5rem;
    }
    .service-section .service-card .content .name{
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        margin-bottom: 2px !important;
    }
    .service-section .service-card .content .text span{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .service-section .service-card .content .des {
        font-size: 0.7rem;
        text-align: center;
        line-height: 1.3;
    } 
} */


/* 

@media (max-width:576px) {
    .service-section .service-card {
        display: flex;
     } 
    .service-section .service-card .image{
        width: 50%;
    }
    .service-section .service-card .image img{
        width: 100%;
        object-fit: contain;
    }
    .service-section .service-card .content{
        width: 100%;
    } 
    .service-section .service-card {
        padding: 1rem;
    }
    .service-section .service-card .content .arrow-btn{
        align-self: flex-end;
    }
    .service-section .service-card .content .name{
        font-size: 1rem;
    } 
    .service-section .service-card .content .des {
        font-size: 0.6rem;
    }    
 .service-section .service-card .content .arrow-btn a{
        width: 25px;
        height: 25px;
        font-size: 1rem;
    } 
} */

 




