.dsa-alerts-section .main-heading h2 {
    font-family: "headingbold" !important;
    font-size: 3rem;
    margin-bottom: 2rem !important;
}

.dsa-alerts-section .alerts-card {
    display: flex;
    padding: 1rem;
    border-radius: 20px;
    background: var(--main-color-one);
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    padding-right: 1rem;
}

.dsa-alerts-section .alerts-card .content {
    padding-right: 4rem;
    padding-left: 1rem;
}

.dsa-alerts-section .alerts-card:nth-child(even) {
    flex-direction: row-reverse;
}

.dsa-alerts-section .alerts-card:nth-child(even) .content {
    padding-left: 4rem;
    padding-right: 1rem;
    text-align: right;
}

.dsa-alerts-section .alerts-card:last-child {
    margin-bottom: 0;
}


.dsa-alerts-section .alerts-card .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dsa-alerts-section .alerts-card .content h4 {
    font-family: "headingbold" !important;
    text-transform: uppercase;
}

.dsa-alerts-section .alerts-card .image {
    width: 30%;
}

.dsa-alerts-section .alerts-card .image img {
    border-radius: 15px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media (max-width:992px) {
    .dsa-alerts-section .main-heading h2 {
        font-size: 2rem;
    }

    .dsa-alerts-section .alerts-card:nth-child(even),
    .dsa-alerts-section .alerts-card {
        flex-direction: column-reverse;
    }

    .dsa-alerts-section .alerts-card .image {
        width: 100%;
        margin-bottom: 2rem;
    }
    .dsa-alerts-section .alerts-card:nth-child(even) .content,
    .dsa-alerts-section .alerts-card .content {
        width: 100%;
        padding: 0;
        text-align: left;
    }
}