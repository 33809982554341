.magazine-page-top-section {
    /* background: var(--main-color-one); */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    position: relative;
    
    
    
}

.magazine-page-top-section .left-img {
    position: absolute;
    /* left: 6rem; */
    /* left: 6vw; */
    /* transform: translateX(5vw); */
    transform: translateX(14vw);
    bottom: 1.2rem;
    /* width: 280px; */
    /* width: 25%; */
    width: 18vw;
    z-index: 15;
}

.magazine-page-top-section .left-img img {
    width: 100%;
    border-radius: 10px;
}

.magazine-page-top-section .center-banner-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:var(--theme-color);
    width: 45vw;
    /* height: 95vh; */
    padding: 2.5rem;

    /* height: 98vh; */
    border-radius: 20px;

}

.magazine-page-top-section .center-banner {
    
    border-radius: 20px;
    width: 29vw;
    /* height: 85vh; */
    transform: rotate(-3deg);
    /* background-color: yellow; */
    /* margin-top: 3rem; */
    /* object-fit: cover; */
    
}
 

.magazine-page-top-section .content-box {
    /* width: 38%;  */
    width: 25vw;
    /* aspect-ratio: 1/1; */
    position: absolute;
    /* right: -2rem; */
    right: 7rem;
    bottom: 6rem;
    background: var(--main-color-one);
    border-radius: 20px;
    padding: 2rem;
    /* height: 59vh; */
    height: 60%;
}

.magazine-page-top-section .content-box h2 {
    font-size: 1.9rem;
    font-family: "headingbold" !important;
}

.magazine-page-top-section .content-box b {
    font-family: "bodyfontbold" !important;
    font-size: 1.2rem;
}

.magazine-page-top-section .content-box p {
    margin-top: .3rem;
    font-size: 1.2rem; 
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
   
}

.magazine-page-top-section .content-box a {
    /* padding: 8px 30px;
    background: var(--black-color);
    color: var(--white-color) !important;
    border-radius: 40px;
    text-transform: capitalize;
    font-weight: bold;
    display: inline-block; */
    margin-top: 0.8rem;
    /* margin-left: 0.5rem; */
     
}



@media(max-width:1800px){
    .magazine-page-top-section .content-box p {
        font-size: 1.29vw;

    }
    .magazine-page-top-section .left-img {
        transform: translateX(8vw);

    }
    .magazine-page-top-section .content-box {
        right: 2rem;

    }
}

@media(max-width:1700px){
    .magazine-page-top-section .left-img {
        transform: translateX(10vw);

    }
    .magazine-page-top-section .content-box {
        right: 4rem;

    }
}


@media(max-width:1600px){
    .magazine-page-top-section .content-box p {
        font-size: 1.2vw;

    }
    .magazine-page-top-section .content-box {
        right: 7rem;

    }
    .magazine-page-top-section .left-img {
        transform: translateX(14vw);

    }
}


@media(max-width:1500px){
    
    .magazine-page-top-section .content-box {
        right: 8rem;

    }
    .magazine-page-top-section .left-img {
        transform: translateX(15vw);

    }
}



@media(max-width:1400px){

    .magazine-page-top-section .content-box h2 {
        font-size: 1.8rem;
    }

    .magazine-page-top-section .content-box p {
        font-size: 1.1vw;

    }
    .magazine-page-top-section .content-box {
        right: 8rem;

    }
    .magazine-page-top-section .left-img {
        transform: translateX(15vw);

    }

} 

@media(max-width:1200px){ 
    .magazine-page-top-section .content-box {
        right: 6rem;

    }
    .magazine-page-top-section .content-box h2 {
        font-size: 1.3rem;
    }    
    .magazine-page-top-section .content-box b {
      font-size: 0.8rem;
    }
    .magazine-page-top-section .content-box p {
        font-size: 1.14vw;

    }
    .magazine-page-top-section .content-box a {
        font-size: 0.6rem;
    }

}

@media(max-width:1100px){
    .magazine-page-top-section .content-box {
        bottom: 5rem;
    }
    .magazine-page-top-section .center-banner {
        scale: 1.02;
    }
    .magazine-page-top-section .content-box p {
        font-size: 1.03vw;

    }

}


@media (max-width:992px) {

    .magazine-page-top-section{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;
        

    }

    .magazine-page-top-section .left-img {
        /* position: absolute; */
        /* width: 100%; */


        left: -4.5rem;
        bottom: 0.8rem;
        /* width: 350px; */
        /* z-index: 15; */
    }
    .magazine-page-top-section .left-img img{
        /* width: 100%; */
    }
    .magazine-page-top-section .center-banner-wrapper {
        padding: 2rem;
    }


    .magazine-page-top-section .content-box {
        /* position: static; */
        /* position: absolute; */
        /* width: 100%; */
        /* width: 50%; */
        /* margin-top: 150px; */
        padding: 1.5rem;
        right: 1rem;
        bottom: 4rem;
    }

    .magazine-page-top-section .content-box h2 {
        font-size: 1rem;
        margin-bottom: 0rem;
    }
    .magazine-page-top-section .content-box a {
        font-size: 0.5rem;
    }
    .magazine-page-top-section .content-box p {
        font-size: 1.01vw;
    }

    /* .magazine-page-top-section .left-img {
        bottom: initial;
        width: 180px;
        top: 180px;
        left: 0;
    } */

}

@media(max-width:576px){
    .magazine-page-top-section .left-img {
        display: none;
    }
}