.editions-category-section .select-edition select{
    padding: 0.5rem;
}
.editions-category-section .select-edition .AnnualEditionsYear label{
    border: 1px solid black;
}
.editions-category-section .edition-card{
    padding-left: 1rem;
    padding-right:1rem;
}
.editions-category-section .edition-card .bottom-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-color-two);
}
.editions-category-section .edition-card .bottom-btn p{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: var(--theme-color);
    font-weight: bolder;
}
.editions-category-section .edition-card .bottom-btn .btn{
    background-color: var(--theme-color ) !important;
    text-align: center;
    border-radius: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
}
.editions-category-section .edition-card .bottom-btn span{
    color: black !important;
    text-decoration: line-through;
    font-size: 0.9rem;
}
.item-card{
    margin-bottom: 1rem;
}
.item-card-des{
    display: flex;
    justify-content: space-between;
} 
.item-card-des p{
    font-size: 1.3rem;
    color: var(--theme-color);
}
.item-card-des span{
    text-decoration: line-through;
    font-size: 1rem;
}
.item-card-des .price{
    color: var(--theme-color);
    font-size: 1.2rem;
    text-decoration: none;
}
.item-card-des .delete-icon svg{
    color: var(--theme-color);
    cursor: pointer;
}