.journey-section {
  width: 100%;
  height: 100%;
  position: relative;
}

.journey-section .main-heading {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  transform: rotate(180deg);
  /* height: 100px; */
  /* display: flex; */
  /* line-height: 1; */
  writing-mode: vertical-lr;
  font-size: 2.7rem;
  margin: 0;
  margin-left: 4px;
}

.journey-section .bgImage {
  width: 100%;
  height: 95vh;

  background-image: url("../../../assets/img/about/Journey\ so\ far_\ Background.png");
}

.journey-section .image {
  width: 100%;
  height: 90vh;

  position: absolute;
  bottom: 0;
  display: flex;

  align-items: center;
  justify-content: center;

  /* background-image: url("../../../assets/img/about/Journey\ so\ far_\ Background.png"); */
  background-size: cover;
}
.journey-section img {
  width: 60%;
  height: 100%;
  aspect-ratio: 1/1;

  object-fit: contain;
  object-position: center;
}

@media (max-width: 992px) {
  .journey-section {
    position: relative;
  }
  .journey-section .bgImage {
    width: 100%;
    height: 83vh;
  }

  .journey-section .image {
    height: 78vh;

    position: absolute;
    bottom: 0;
    display: flex;

    background-size: cover;
  }

  .journey-section img {
    width: 100%;
    height: 100%;
  }
}


@media (max-width: 768px) {
  .journey-section {
    position: relative;
  }
  .journey-section .bgImage {
    width: 100%;
    height: 83vh;
  }

  .journey-section .image {
    height: 78vh;

    position: absolute;
    bottom: 0;
    display: flex;

    background-size: cover;
  }
  .journey-section img {
    width: 100%;
        height: 100%;
      }
 }




@media (max-width:576px){  

  .journey-section .bgImage{
    width: 100%;
    height: 60vh;
    
  } 
  .journey-section .image{
    height: 56vh; 
  }
  .journey-section img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width:450px) {
  .journey-section img {
    /* object-position: bottom; */
  }
  .journey-section .image{
    height: 54vh; 
  }
  
}
