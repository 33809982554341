.dsa-audit-section {
    position: relative;
}

.dsa-audit-section .main-heading {
    margin-bottom: 2rem;
    font-size: 3rem;
}

.dsa-audit-section .center-heading {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    z-index: 9;
}

.dsa-audit-section .center-heading span {
    display: block;
    font-size: 2.2rem;
    font-style: italic;
    color: var(--font-light);
    text-transform: uppercase;
    font-weight: bolder;
}

.dsa-audit-section .center-heading h2 {
    font-size: 3rem;
    font-family: "headingbold" !important;
    text-transform: uppercase;
    color: #636669;
}

.dsa-audit-section img {
    width: 100%;
    border-radius: 20px;
    filter: grayscale(1) contrast(1) brightness(1);
    /* mix-blend-mode: multiply; */
    object-fit: cover;
    object-position: left;
}

.dsa-audit-section .position-relative img {
    width: 85%;
    height: 85%;
}

.dsa-audit-section .bottom-img {
    width: 200px !important;
    height: 200px !important;
    position: absolute;
    bottom: -50px;
    right: 0;
    /* filter: grayscale(1) contrast(1) brightness(3); */
}

.dsa-audit-section .name {
    font-weight: bolder;
    text-transform: capitalize;
    margin: 10px 0 !important;
    font-size: 1.2rem;
}

.dsa-audit-section .bottom-date .box {
    display: flex;
    gap: 10px;
    align-items: center;
}
.dsa-audit-section .bottom-date .box p:last-child{
    font-size: .8rem;
    margin: 0;
}

@media (max-width:992px) {
    .dsa-audit-section .main-heading{
        /* font-size: 1.5rem; */
        font-size: 2.5rem;
    }
    .dsa-audit-section .center-heading{
        width: 90%;
        text-align: center;
    }
    .dsa-audit-section .center-heading span{
        font-size: 2rem;
    }
    .dsa-audit-section .center-heading h2{
        /* font-size: 1.5rem; */
        font-size: 2.5rem;
    }

/* 
    .dsa-audit-section .position-relative img{
        aspect-ratio: 1/0.5;
    } */


    .dsa-audit-section img{
        height: 70%;
        aspect-ratio: 1/0.5;

    }
}

@media(max-width:768px){
    .dsa-audit-section .bottom-img {
        width: 160px!important;
        height: 160px !important;
    }
}


@media(max-width:576px){
    .dsa-audit-section .bottom-img {
        width: 140px!important;
        height: 140px !important;
    }
    .dsa-audit-section img{
       
        aspect-ratio: 1/0.6;

    }



    .dsa-audit-section .center-heading span{
        font-size: 1.5rem;
    }
    .dsa-audit-section .center-heading h2{
        /* font-size: 1.5rem; */
        font-size: 2rem;
    }

}

@media(max-width:375px){
.dsa-audit-section .main-heading{
     font-size: 1.8rem;
}
}