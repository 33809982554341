.top-section-section .left-box {
    background: var(--main-color-one);
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: 100%;
}

.top-section-section .content {
    position: absolute;
    top: 0;
    left: 1rem;
    padding: 2rem;
}

.top-section-section .content h2 {
    font-size: 3rem;
}

.top-section-section .content p {
    font-size: 1.2rem;
}

.top-section-section img {
    border-radius: 20px;
    width: 100%;
    height: 500px;   
    object-fit: cover;
}


@media(max-width:1200px){
    .top-section-section .content h2 {
        font-size: 2.1rem;
    }   
.top-section-section .content p {
    font-size: 1rem;
}
}


@media (max-width:991.98px) {
    .top-section-section{
        /* margin-top: 4rem; */
        margin-top: 2rem;
    }
    .top-section-section .content h2{
        font-size: 2rem;
    }
    .top-section-section .left-box{
        /* margin-bottom: 3rem; */
        /* height: 300px; */
        height: auto;
    }
    .top-section-section .content{
        position: static;
    }
    .top-section-section img{
        margin: 3rem 0;
        height: auto;
        /* height: 59%; */
        object-position: top;
        aspect-ratio: 1/0.6;
    }
}
@media(max-width:576px){
    .top-section-section{
        margin-top: 0rem;
    }
    

}