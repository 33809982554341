.about-gallery-section {
    margin-bottom: -5rem;
}

.about-gallery-section .top-heading p{
    font-family: "Beau Rivage", cursive !important;
    font-size: 2rem;
    color: var(--black-color);
    text-transform: capitalize;
}

.about-gallery-section .main-heading h2{
    font-family: "headingbold" !important;
    font-size: 3rem;
    color: var(--theme-color);
}
.about-gallery-section .main-heading p{
    text-transform: initial !important;
    width: 50%;
    margin: 0 auto;
    font-family: "bodyfontbold";
}
.about-gallery-section .swiper-backface-hidden .swiper-slide{
    margin: 0;
}
.about-gallery-section .mySwiper img{
    width: 100%;
    /* height: 318px; */
    height: 45vh;
    /* height: 80%; */
    /* height: auto; */
    object-fit: cover;
    /* margin-bottom: 1.5rem; */
    padding-bottom: 1.5rem;

}


.about-gallery-section .mySwiper .swiper-pagination-bullet-active {
    background-color: var(--theme-color) !important;
  }
@media (max-width:992px) {
    .about-gallery-section {
        padding-bottom: 0;
    }
    .about-gallery-section .main-heading p{
        width: 64%;
        /* width: 100%; */
    }
    .about-gallery-section .main-heading h2{
        font-size: 1.5rem;
    }
    .about-gallery-section .top-heading p{
        font-size: 1.2rem;
    }
    .about-gallery-section .swiper-backface-hidden .swiper-slide{
        margin: 0;
    }
    /* .about-gallery-section .mySwiper .col-4,
    .about-gallery-section .mySwiper .col-8{
        padding: 5px;
    } */
    .about-gallery-section .mySwiper img{
        /* height: auto; */
        /* height: 94%; */
        height: 100%;
        padding-bottom: 1.5rem;
        /* margin-bottom: 1.5rem; */
    }
}
@media(max-width:768px){
    .about-gallery-section .main-heading p {
        width: 86%;
    }
}

@media(max-width:576px){
    .about-gallery-section .main-heading p {
        font-size: 0.9rem;
    }

}

@media(max-width:450px){
    .about-gallery-section .main-heading p {
        width: 100%;
    }
}