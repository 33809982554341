.home-banner .count-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.home-banner .count-card:last-child {
  margin-bottom: 0;
}

.home-banner .count-card p {
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  font-family: "headingbold" !important;
  color: var(--theme-color);
  line-height: 1;
}

.home-banner .count-card span {
  font-size: 1.3rem;
  text-decoration: underline;
  /* text-decoration-color: #636669; */
  text-underline-offset: 10px;
  color: var(--font-dark);
  letter-spacing: 2px;
}
.home-banner .count-card .content {
  margin-top: 1.1rem;
}

.home-banner .banner-section {
  position: relative;
  /* height: 100%; */
}

.home-banner .banner-section .mySwiper {
  height: 100%;
}

.home-banner .banner-section .mySwiper img {
  width: 100%;
  /* height: 500px; */
  /* height: 68vh; */
  height: 34vw;
  border-radius: 20px;
}

.home-banner .banner-section .mySwiper .swiper-pagination-bullet-active {
  background-color: var(--white-color) !important;
}

.home-banner .banner-section .banner-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  /* padding: 20px; */
  /* padding-bottom: 25px; */
  padding-left: 0;
  /* border-radius: 0 0 20px 0; */
  /* background: var(--white-color); */
}

.home-banner .banner-section .banner-btn img {
  /* width: 255px !important; */
  /* width:600px !important; */
  width: 270px !important;

  margin-left: -1px;
  /* object-fit: contain; */
  /* position: absolute;
    top: 0;
    left: 0; */
  /* z-index: 0; */
}

.home-banner .banner-section .banner-btn a {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 10px 15px !important;
  background-color: var(--theme-color);
  color: var(--white-color) !important;
  text-transform: capitalize;
  font-family: "bodyfont" !important;
  font-weight: bolder;
  letter-spacing: 1px;
  border-radius: 40px !important;
  border: 2px solid var(--theme-color);
  transition: 0.3s;
}

.home-banner .banner-section .banner-btn a:hover {
  background: var(--white-color);
  color: var(--theme-color) !important;
}

.home-banner .banner-section .banner-btn a svg {
  transform: rotate(-40deg) translateY(-3px);
  font-weight: normal;
}

.home-banner .banner-section .bottom-three-circle {
  position: absolute;
  bottom: -28px;
  right: 2rem;
  /* transform: translateY(50%); */
  z-index: 9;
  display: flex;
  gap: 0 1.2rem;
}

.home-banner .banner-section .bottom-three-circle p {
  height: 60px;
  width: 60px;
  padding: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  background: var(--theme-color);
  border: 2px solid var(--white-color);
}
.home-banner .banner-section .bottom-three-circle p img {
  filter: brightness(0) invert(1);
}

.home-banner .banner-section .bottom-three-circle p:hover {
  /* border: 2px solid var(--theme-color); */
  background-color: var(--white-color);
}

.home-banner .banner-section .bottom-three-circle p:hover img {
  filter: none;
}

.home-banner .banner-section .mySwiper .swiper-pagination-bullet-active {
  background: var(--white-color) !important;
}

@media (max-width: 1200px) {
  .home-banner .count-card span {
    font-size: 1rem;
  }
}

@media (max-width: 992px) {
  .home-banner .count-card p {
    font-size: 2.5rem;
  }
  /* .home-banner .count-card .content{
        margin-top: 1.1rem;
    } */

  .home-banner .count-card span {
    font-size: 0.9rem;
  }
  .home-banner .banner-section .mySwiper .swiper-slide {
    height: auto;
  }
  .home-banner .banner-section .mySwiper img {
    height: 100%;
    /* aspect-ratio: 1/1; */
    object-fit: cover;
  }
  /* 
    .home-banner .banner-section {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }
     */

  .home-banner .banner-section .banner-btn a {
    font-size: 1rem;
    padding: 5px 10px !important;
    font-size: 0.8rem;
  }
  .home-banner .banner-section .banner-btn img {
    width: 210px !important;
    margin-top: -2px;
  }
  .home-banner .banner-section .bottom-three-circle {
    gap: 10px;
    right: 1rem;
    bottom: -20px;
  }
  .home-banner .banner-section .bottom-three-circle p {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 768px) {
  .home-banner .count-card {
    margin: 0rem 1.5rem;
  }

  /* .home-banner .count-card p{
        font-size: 2rem;
       
    } */
  .home-banner .banner-section {
    margin-bottom: 2rem;
  }
}

@media (max-width: 576px) {
  .home-banner .count-card p {
    /* font-size: 1.5rem; */
    font-size: 2.1rem;

  }
  .home-banner .count-card span {
    font-size: 0.8rem;
  }
  .home-banner .count-card .content {
    margin-top: 0.8rem;
  }
  .home-banner .banner-section .banner-btn a {
    font-size: 0.8rem;
    padding: 5px 10px !important;
  }

  .home-banner .banner-section .bottom-three-circle p {
    width: 40px;
    height: 40px;
  }
}

@media(max-width:450px){
  .home-banner .count-card {
    margin: 0rem 0.5rem;
  }
  .home-banner .banner-section .bottom-three-circle {
    gap: 5px;
    
  }

  
}
@media(max-width:375px){
  .home-banner .count-card span{
    font-size: 0.7rem;

  }
  .home-banner .count-card p {
    font-size: 1.7rem;
  }
}
