.founder-section .left-section {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding-top: 1rem;
}

.founder-section .left-section h2 {
    font-size: 3rem;
}

.founder-section .left-section p {
    font-size: 1.1rem;
    text-align: justify;
}

.founder-section .left-section .sub {
    color: #636669;
    font-size: 1.5rem;
    font-family: "bodyfontbold" !important;
}

.founder-section .left-section a {
    padding: 8px 30px;
    border: 2px solid var(--theme-color);
    color: var(--theme-color) !important;
    border-radius: 30px;
    font-size: 1.2rem;
    transition: .3s all;    
    margin-top: 1rem;
    font-weight: bolder;
    display: inline-block;
    width: fit-content;
}

.founder-section .left-section a:hover {
    background: var(--theme-color);
    color: var(--white-color) !important;
}


/* Same as ceoSection */

.founder-section .right-section {
     
     
    background-color: var(--main-color-one);
   
    /* background-color: var(--theme-color); */
     
    padding: 0.9rem;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    margin-right: 0.5rem;
    width: 45%;
    
    
}

.founder-section .right-section img {
    border-radius: 15px;
    width: 95%;
 
    aspect-ratio: 1.8/1.4   ;
    object-fit: cover;  
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    object-position: center;
    filter: grayscale(1) contrast(1) brightness(1);
    mix-blend-mode: multiply;
}

.founder-section .right-section .name {
    font-size: 1.8rem;
    font-family: "bodyfontbold" !important;
    text-transform: uppercase;
}

.founder-section .right-section .name span {
    font-size: 0.8rem;
    color: #636669;
}
.founder-section .right-section > span{
    font-size: 1.6rem;
    font-style: italic;
    margin-bottom: 0.5rem;
    display: inline-block;
}

.founder-section .right-section .text-end{
    font-size: .8rem;
}
 
.comittesd-section{
    padding: 20px;
}


@media(max-width:1800px){
    
.founder-section .left-section p {
    font-size: 1.5rem;
}
}

@media(max-width:1600px){
    
    .founder-section .left-section p {
        font-size: 1.3rem;
    }
     
}


@media(max-width:1400px){
    
    .founder-section .left-section p {
        font-size: 1.1rem;
    }
     
}
@media(max-width:1200px){
    
    .founder-section .left-section p {
        font-size: 0.9rem;
    }
     
}



@media (max-width:992px) {

    .founder-section .left-section h2 {
        font-size: 2.9rem;
    }
    .founder-section .left-section p {
        font-size: 1.1rem;
    }

    .founder-section .right-section {
        /* width: 100%; */
        width: 44%;
        transform: translate(64%);
        
    }

    
.founder-section .right-section img {
    aspect-ratio: 0.8/0.55;
    /* width: 80%; */
    /* height: 70%; */
    object-position: center;
}
    .founder-section .right-section{

        margin-top: 1.5rem;
        
        margin-bottom: 3rem;
    }
    .founder-section .right-section .name{
        font-size: 1.5rem;
    }
 

}

@media(max-width:768px){

    .founder-section .left-section h2 {
        font-size: 2.8rem;
    }
    .founder-section .right-section .name{
        font-size: 1.3rem;
    }
    .founder-section .right-section > span {
        font-size: 1.5rem;
    }

}

@media(max-width:576px){
    .founder-section .left-section h2 {
        font-size: 2.5rem;
    }
    
    .founder-section .left-section p {
        font-size: 1rem;
    }

    .founder-section .right-section {
        /* width: 100%; */
        width: 57%;
        transform: translate(39%);
        
    }
    .founder-section .right-section .name{
        font-size: 1rem;
    }
    .founder-section .right-section > span {
        font-size: 1.3rem;
    }
    .founder-section .left-section a {
        padding: 4px 15px;
        font-size: 1rem;
    }

}

@media(max-width:449.98px){
    .founder-section .left-section h2 {
        font-size: 2rem;
}
.founder-section .right-section {
    width: 100%; 
    transform: translate(0%); 
}
}

@media(max-width:375px){
    .founder-section .left-section h2 {
        font-size: 1.7rem;
}

}







/* previous card css */
/* 
.founder-section .right-section img {
    width: 100%;
    height: 500px;
    border-radius: 20px 0 0;
    filter: grayscale(1) contrast(1) brightness(1);
    mix-blend-mode: multiply;
} */
/* 
.founder-section .right-section .main-heading {
    color: var(--black-color);
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 0;
    display: flex;
    align-items: baseline;
    justify-content: center;
    text-align: center;
    gap: 10px;
} */
/* .founder-section .right-section .main-heading h2{
    font-family: "headingbold" !important;
    font-size: 1.5rem;
    letter-spacing: 2px;
}

.comittesd-section {
    padding: 20px 0;
} */

/* 

@media (max-width:992px) {
    .founder-section .left-section h2{
        font-size: 1.5rem;
    }
    .founder-section .right-section img{
        border-radius: 20px 20px 0 0;
        margin-top: 3rem;
    }
    .founder-section .right-section .main-heading{
        left: 0;
    }
    .founder-section .right-section .main-heading h2{
        font-size: 1.3rem !important;
    }
} */