.newsletter-section {
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
    background: var(--main-color-one);
     
}

.newsletter-section .swiper-slide {
    height: auto !important;
}

.newsletter-section .newsletter-card {
    /* display: flex; */
    height: 100%;
    background: var(--white-color);
    border-radius: 5px;
}

.newsletter-section .newsletter-card .image {
    width: 30%;
    aspect-ratio: 1.5/1.2;
   
}

.newsletter-section .newsletter-card .image img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-fit: cover;
    border-radius: 5px;

    /* padding: 1rem; */
}

.newsletter-section .newsletter-card .content {
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.newsletter-section .newsletter-card .content h5 {
    font-weight: bolder;
    color: var(--theme-color);
}

.swiper-button-prev,
.swiper-button-next{
    --swiper-navigation-size:25px;
    
    color: var(--theme-color);
     font-weight:900;
     /* margin-top: 2rem; */
}


@media (max-width:992px) {
    
    .newsletter-section .newsletter-card .content h5{
        font-size: .9rem;
    }
    
}