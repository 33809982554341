.podcast-detail-section .ant-collapse-borderless {
  background: transparent !important;
  border-radius: 0 !important;
}

.podcast-detail-section .ant-collapse-header {
  /* padding-bottom: 1rem !important; */
}

.podcast-detail-section .podcast-card {
  display: flex;
  width: 100%;
}

.podcast-detail-section .podcast-card .content .heading h4 {
  font-family: "headingbold" !important;
  text-transform: capitalize;
  letter-spacing: 2px;
  color: var(--font-dark);
  /* font-weight: bolder !important; */
}

.podcast-detail-section .podcast-card .content .heading p {
  font-family: "bodyfont" !important;
  font-weight: bolder;
  font-size: 1.1rem;
}

.podcast-detail-section .podcast-card .content .date .box {
  display: flex;
}

.podcast-detail-section .podcast-card .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
  padding-right: 2rem;
}

.podcast-detail-section .podcast-card .number {
  align-self: center;
  margin-right: 1rem;
  font-size: 1.2rem;
  /* font-family: "headingbold" !important; */
  font-weight: bolder;
}

.podcast-detail-section .podcast-card .image {
  margin-right: 1.3rem;
}

/* .podcast-detail-section .podcast-card .image .d-flex{
    display: none !important;
} */

.podcast-detail-section .podcast-card .image img {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.podcast-detail-section .ant-collapse-item {
  position: relative;
}

.podcast-detail-section .ant-collapse-expand-icon {
  position: absolute;
  bottom: 10px;
  right: 1rem;
  width: 40px;
  height: 40px !important;
  background: var(--theme-color);
  color: var(--white-color) !important;
  border-radius: 50%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}

.podcast-detail-section .ant-collapse-expand-icon .ant-collapse-arrow {
  font-size: 1rem !important;
}

.podcast-detail-section .bottom-content {
  margin-left: auto;
  display: flex;
  padding-right: 3rem;
}

.podcast-detail-section .bottom-content .content {
  margin-top: -120px;
   
  margin-left: 2.6rem;
  padding-right: 1rem;
  /* transform: translateY(-100px); */
  width: 60% !important;
}

.podcast-detail-section .bottom-content .content a {
  display: inline-block;
  padding: 10px 30px;
  border-radius: 20px;
  margin-top: 2rem;
  background: var(--main-color-two);
  font-weight: 700;
  font-size: .9rem !important;
}

.podcast-detail-section .bottom-content .youtube-link {
  width: 25%;
  padding-left: 2.4rem;
  padding-right: 1rem;
  margin-top: -15px;
}

.podcast-detail-section .bottom-content .youtube-link a {
  background: var(--main-color-two);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width:992px) {
  .podcast-detail-section .podcast-card .content{
    flex-wrap: wrap;
    padding-right: 3rem;
  }
  .podcast-detail-section .podcast-card {
    flex-direction: column;
  }
  .podcast-detail-section .podcast-card .image{
    margin-right: 0;
  }
  .podcast-detail-section .bottom-content .content{
    margin: 0;
    margin-left: 0;
    width: 100% !important;
    padding: 0;
  }
  .podcast-detail-section .bottom-content{
    padding: 0;
    margin-top: 2rem;
    flex-direction: column;
  }
  .podcast-detail-section .bottom-content .youtube-link{
    padding: 0;
    margin-right: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}