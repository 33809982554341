.region-section .main-heading h2 {
    font-size: 3rem;
    font-family: "headingbold" !important;
    margin-bottom: 2rem !important;
}
.region-section .mySwiper{
    padding: 20px;
}
.region-section img {
    width: 85%;
    border-radius: 20px;
}
.region-section img:hover{
    transform: scale(1.1);
}

@media (max-width:992px) {
    .region-section .main-heading h2{
        font-size: 2rem;
    }
}