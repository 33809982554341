.articles-section .left-swiper-box {
  /* position: relative; */
  /* left: 0; */
  /* top: 0; */
  /* right: 0; */
  /* width: 100%; */
  border-radius: 20px;
  overflow: hidden;
  /* transform: translateX(calc(-1.5rem* .5)); */
}
/* .articles-section .heading-box p{
  font-size: .9rem;
} */
.articles-section .heading-box {
  padding-right: -15rem;
}
.articles-section .heading-box .des {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.articles-section .heading-box a {
  padding: 8px 30px;
  border: 2px solid var(--theme-color);
  color: var(--theme-color) !important;
  border-radius: 30px;
  font-size: 1.2rem;
  transition: 0.3s all;
  display: inline-block;
}

.articles-section .heading-box a svg {
  font-size: 1.5rem;
  margin-left: 10px;
}

.articles-section .heading-box a:hover {
  background: var(--theme-color);
  color: var(--white-color) !important;
}
.articles-section .left-swiper-box .article-left-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 551px;
  background: var(--main-color-two);
}

.articles-section .left-swiper-box .article-left-card .right-content-box {
  position: absolute;
  z-index: 9;
  /* top: 52%; */
  top: 49%;
  /* left: 85%; */
  left: 76%;
  transform: translate(-65%, -50%);
  /* transform: translate(-85%, -50%); */
}

.articles-section
  .left-swiper-box
  .article-left-card
  .right-content-box
  .top-section {
  transform: translateX(-80px);
}

.articles-section
  .left-swiper-box
  .article-left-card
  .right-content-box
  .top-section
  h4 {
  text-transform: uppercase;
  font-size: 1 5rem;
  font-family: "headingbold" !important;
  font-weight: bolder;
  margin-bottom: 1.1rem;
  color: var(--theme-color);
  margin-top: 1rem;
}

.articles-section .left-swiper-box .article-left-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 62%;
  height: 100%;
  background: var(--main-color-one);
}

.articles-section .left-swiper-box .article-left-card .image {
  /* width: 275px; */
  width: 20vw;
  aspect-ratio: 1/1;
  background-color: var(--main-color-one);
  padding: 10px;
  border-radius: 10px;
  /* transform: tr; */
}

.articles-section .left-swiper-box .article-left-card .image img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.articles-section .left-swiper-box .article-left-card .content {
  /* margin-top: 2rem; */
  padding-top: 1rem;
  margin-left: 0.5rem;
}
.articles-section .left-swiper-box .article-left-card .content .des {
  color: var(--theme-color);
  font-weight: bolder;
  font-size: 0.9rem;
}

.articles-section .left-swiper-box .article-left-card .content span {
  font-size: 0.9rem;
  color: #636669;
  margin-bottom: 5px;
  display: inline-block;
}

.articles-section .left-swiper-box .article-left-card .content a {
  /* text-decoration: underline !important; */
  text-underline-offset: 4px;
  margin-top: 3px;
  padding-bottom: 2rem;
  display: inline-block;
  font-size: 0.8rem;
}
.articles-section .left-swiper-box .article-left-card .content a:hover {
  color: var(--theme-color) !important;
}

.articles-section .left-swiper-box .swiper-pagination-bullet-active {
  background-color: var(--theme-color) !important;
}

.articles-section .right-section {
  border-radius: 20px;
  overflow: hidden;
  /* transform: translateX(calc(1.5rem* .5)); */
  width: 100%;
  height: 90%;
  position: relative;
  display: flex;
}

.articles-section .right-section .heading {
  width: 100px;
  background-color: var(--main-color-one);
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* text-align: right; */
  /* position: relative; */
}

.articles-section .right-section .heading h2 {
  height: -15vh;
}
.articles-section .right-section .heading h2 img {
  /* margin-bottom: 8rem; */
  /* font-size: 3rem; */
  padding-bottom: 25px;

  /* font-weight:bolder; */
  /* transform: rotate(0deg) translateX(0px); */
  /* margin-bottom: 0; */
  height: 38vh;

  /* height: 100%; */
}

.articles-section .right-section .cards-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  gap: 1rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.articles-section .right-section .cards-box .top-section {
  width: 92%;
  height: 50%;
  position: relative;
  right: 0;
  border-radius: 0 20px 20px 20px;
  margin-left: auto;
  overflow: hidden;
  z-index: 9;
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  align-items: flex-end;
}

.articles-section .right-section .cards-box .bottom-section {
  width: 100%;
  height: 50%;
  position: relative;
  z-index: -1;
  padding: 1rem;
  padding-left: 120px;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
}

.articles-section .right-section .cards-box img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.articles-section .right-section .cards-box .content {
  /* width: 60%; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--theme-color-gradiant);
  color: var(--white-color);
  padding: 1rem;
}

.articles-section .right-section .cards-box .content span {
  font-size: 0.9rem;
  color: var(--white-color);
}

.articles-section .right-section .cards-box .content p {
  font-size: 1.3rem;
  text-transform: capitalize;
  font-weight: 500;
}
.articles-section .right-section .bottom-section .content {
  padding-left: 120px;
}
.articles-section .view-event-btn {
  margin-left: calc(1.5rem * 0.5);
  margin-top: 1.7rem;
  display: flex;
  justify-content: center;
}

.articles-section .view-event-btn a {
  padding: 10px 30px;
  background: var(--theme-color);
  color: var(--white-color) !important;
  border-radius: 25px;
  font-size: 1.5rem;
  line-height: 1;
}

.articles-section .view-event-btn a:hover {
  background: var(--white-color);
  color: var(--theme-color) !important;
  border-radius: 25px;
  border: 2px solid var(--theme-color);
}

.articles-section .view-event-btn a svg {
  margin-left: 10px;
}




@media(max-width:1800px){
  .articles-section .left-swiper-box .article-left-card {
    height: 56vh;
  }
  .articles-section .left-swiper-box .article-left-card .right-content-box {
    left: 73%;
  }
}
@media(max-width:1600px){
  .articles-section .left-swiper-box .article-left-card {
    height: 71vh;
  }

}
 @media(max-width:1500px){
  .articles-section .left-swiper-box .article-left-card {
    height: 61vh;
  }

}  

@media(max-width:1300px){
  .articles-section .left-swiper-box .article-left-card {
    height: 67vh;
  }

}

@media(max-width:1200px){
  .articles-section .left-swiper-box .article-left-card {
    height: 68vh;
  }
  .articles-section .left-swiper-box .article-left-card .right-content-box {
    left: 79%;
  }
  .articles-section .left-swiper-box .article-left-card .right-content-box .top-section h4 {
    font-size: 1.1rem;
  }
  

}
 




@media (max-width: 992px) {
  /* .articles-section
  .left-swiper-box
  .article-left-card
  .right-content-box
  .top-section
  h3 {
    font-size: 1.5rem;
  } */

  .articles-section .heading-box a {
    padding: 3px 20px;
  }
  .articles-section .left-swiper-box .article-left-card {
    height: 74vh;
    /* height: 498px; */
  }
  .articles-section .left-swiper-box .article-left-card .right-content-box {
    left: 76%;
  }
  .articles-section
    .left-swiper-box
    .article-left-card
    .right-content-box
    .top-section {
    transform: translate(0);
  }
  .articles-section
    .left-swiper-box
    .article-left-card
    .right-content-box
    .top-section
    h4 {
    font-size: 1.1rem;
  }
   

  .articles-section .heading-box .des {
    font-size: 0.9rem;
  }

  .articles-section .left-swiper-box .article-left-card .content .des {
    font-size: 0.8rem;
  }

  .articles-section .left-swiper-box .article-left-card .content a {
    padding-bottom: 0rem;
  }

  .articles-section .left-swiper-box .article-left-card::before {
    /* width: 53%; */
    width: 62%;
  }

  .articles-section .left-swiper-box .article-left-card .image {
    width: 26vw;
  }

  .articles-section .left-swiper-box .article-left-card .right-content-box {
    position: absolute;
    transform: translate(-84%, -53%);
  }
  .articles-section .left-swiper-box .article-left-card .content {
    margin-left: 4.5rem;
  }
  .articles-section .right-section {
    /* margin-top: 3rem; */
    /* height: auto; */
    height: 89%;
    /* aspect-ratio: 1/2.5; */
    aspect-ratio: 1/1;
  }
  .articles-section .right-section .cards-box .content p {
    font-size: 1.1rem;
  }
  .articles-section .view-event-btn a {
    font-size: 1.2rem;
  }
}

@media (max-width: 767.98px) {
  .articles-section .left-swiper-box .article-left-card {
    height: 71vh;
     
  }
  .articles-section .left-swiper-box .article-left-card .image {
    width: 32vw;
  }

  .articles-section .left-swiper-box .article-left-card .right-content-box {
    position: absolute;
    transform: translate(-134%, -53%);
  }

  .articles-section .right-section .heading h2 img {
    height: 31vh;
  }

  .articles-section .right-section {
    margin-top: 3rem;
  }

  .articles-section .right-section {
    aspect-ratio: 1/0.5;
  }
  .articles-section .view-event-btn a {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .articles-section .left-swiper-box .article-left-card .image {
    width: 43vw;
  }
  .articles-section .right-section .heading h2 img {
    height: 22vh;
  }

  .articles-section .left-swiper-box .article-left-card .right-content-box {
    position: absolute;
    transform: translate(-103%, -53%);
  }

  .articles-section .view-event-btn a {
    font-size: 0.9rem;
  }
}

@media(max-width:450px){
  .articles-section .left-swiper-box .article-left-card {
    height: 64vh;
  }
  .articles-section .left-swiper-box .article-left-card .content {
    width: 98%;
  }


}