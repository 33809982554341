.contact-us-form .main-heading h2 {
  font-family: "headingbold" !important;
}

.contact-us-form b {
  font-size: 1.8rem;
  text-transform: capitalize;
  font-family: "bodyfontbold" !important;
}

.contact-us-form .contact-links {
  border-right: 2px solid var(--black-color);
  height: 71vh;
}

.contact-us-form .contact-links .box {
  /* margin-bottom: 3rem; */
  margin-bottom: 2rem;
}

.contact-us-form .contact-links .box p {
  font-size: 1.8rem;
  font-family: "bodyfontbold" !important;
  /* margin-bottom: 1rem !important; */
  text-transform: uppercase;
}
.contact-us-form .contact-links .box a {
  font-size: 1.4rem;
}

.contact-us-form .conact-form {
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}

.contact-us-form .conact-form input {
  height: 9.62vh;
  /* height: 71px; */
}
.contact-us-form .conact-form textarea {
  padding: 25rem;
  height: 29vh;
}

.contact-us-form .conact-form input,
.contact-us-form .conact-form textarea {
    border: none;
    outline: none;
    margin-bottom: 2rem;
    /* padding: 2rem; */
    padding: 1rem;
    /* padding: 1.5rem 1rem 10px; */
    background: var(--main-color-one);
    position: relative;
}

.contact-us-form .conact-form input::placeholder,
.contact-us-form .conact-form textarea::placeholder {
  color: var(--black-color);
  position: absolute;
  bottom: 10px;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 1.1rem;
}

.contact-us-form .conact-form button {
  /* padding: 8px 25px;
    background: var(--main-color-two);
    border-radius: 20px;
    text-transform: capitalize;
    font-weight: bold;
    display: inline-block;
    width: auto;
    border: none;
    outline: none; */
}

.contact-call-section {
  background: var(--main-color-one);
  /* margin-bottom: 6rem; */
  position: relative;
  /* top: 12vh; */
  padding: 50px !important;
  /* height: 15vh; */
  margin-bottom: -5.8rem;
}

.contact-call-section svg {
  color: var(--theme-color);
}

.contact-call-section a {
  padding: 8px 30px;
  color: var(--white-color) !important;
  background-color: var(--theme-color);
  border-radius: 40px;
  text-transform: capitalize;
  font-weight: bold;
  display: inline-block;
}

.contact-call-section a:hover {
  color: var(--theme-color) !important;
  border: 3px solid var(--theme-color);
  background-color: var(--main-color-one);
}

.contact-call-section p {
  font-size: 2rem;
  font-style: italic;
}
.contact-links .sm-links {
  display: flex;
  gap: 1rem;
  /* margin-top: 1rem; */
}
@media(max-width:1400px){
.contact-us-form .contact-links{
    height: 68.5vh;
}
} 
@media (max-width: 992px) {
  .contact-us-form .main-heading h2 {
    font-size: 1.8rem;
  }
  .contact-us-form b {
    font-size: 1.7rem;
}

  contact-us-form .contact-links {
    border-right: 2px solid var(--black-color);
    /* height: 71vh; */
  }

.contact-us-form .contact-links {
        
        height: 73vh;
    }
    
.contact-us-form .contact-links .box {
    /* margin-bottom: 1.5rem; */
  }

  .contact-us-form .contact-links .box p {
    font-size: 1.4rem;
  }
  .contact-us-form .contact-links .box a {
    font-size: 1rem;
}

.contact-links .sm-links {
    gap: 0.5rem;   
}

  /*     
    .contact-us-form .contact-links{
        border-right: 0;
        border-bottom: 2px solid var(--black-color);
        margin-bottom: 3rem;
        height: 74vh;
    }
    .contact-us-form .contact-links .box {
        margin-bottom: 2rem;
    }
     */
  .contact-call-section {
    /* top: 18vh; */
    top: 4vh;
    /* margin-bottom: 6rem; */
    padding-left: 0!important;
    padding-right: 0 !important;
  }

  .contact-call-section .d-flex {
    justify-content: center;
  }
  .contact-call-section p {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  /* .contact-us-form .contact-links{
        height: 70vh;
    } */
    .contact-us-form b {
        font-size: 1.2rem;
    }

    .contact-us-form .contact-links{
        border-right: 0;
        /* border-bottom: 2px solid var(--black-color); */
        /* border-top: 2px solid var(--black-color); */
        /* margin-bottom: 3rem; */
        height: 44vh;
        /* height: auto; */

        margin-top: 4rem;
    } 


  .contact-us-form .contact-links .box {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 450px) {
    .contact-us-form .main-heading h2 {
        font-size: 1.7rem;
    }
    .contact-call-section a {
      font-size: 1.4rem;
    }
}

@media (max-width: 375px) {
  .contact-us-form .main-heading h2 {
      font-size: 1.4rem;
  }
  .contact-call-section p {
    font-size: 1.3rem;
  }
}