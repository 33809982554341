.magazine-navigation h1 {
  font-size: 4.8rem;
  text-align: center;
}

.magazine-navigation .navigation-bar .name {
  font-size: 1.2rem;
}
.magazine-navigation .navigation-bar {
  background: var(--main-color-one);
  padding: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.magazine-navigation .navigation-bar .inner-links {
  display: flex;
  border-bottom: 1px solid var(--black-color);
  margin-left: 8px !important;
  margin-bottom: 10px !important;
  width: 100%;
  justify-content: space-evenly;
  font-size: 1.2rem;
}

.magazine-navigation .navigation-bar .sm-links ul {
  display: flex;
}
.magazine-navigation .navigation-bar .sm-links ul li a {
  background: var(--main-color-two);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: 0.3s;
}
.magazine-navigation .navigation-bar .sm-links ul li a:hover {
  background: var(--theme-color);
}
.magazine-navigation .navigation-bar .sm-links ul li a:hover svg {
  color: var(--white-color);
}
.navigation-bar .name:hover {
  color: var(--theme-color);
  cursor: pointer;
  font-weight: bolder;
}
/* 
@media (max-width: 992px) {
  .magazine-navigation h1 {
    font-size: 2rem;
    margin-bottom: 2rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links,
  .magazine-navigation .navigation-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .magazine-navigation .navigation-bar {
    padding: 1rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links {
    margin-left: 0 !important;
    margin-bottom: 1.5rem !important;
    padding-bottom: 1rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links li {
    margin-top: 10px;
  }
  .magazine-navigation .navigation-bar .sm-links ul li a {
    margin-right: 10px;
    margin-left: 0;
  }
} */

@media (max-width: 768px) {
  .magazine-navigation h1 {
    font-size: 2rem;
    margin-bottom: 2rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links,
  .magazine-navigation .navigation-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  .magazine-navigation .navigation-bar {
    padding: 1rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links {
    margin-left: 0 !important;
    margin-bottom: 1.5rem !important;
    padding-bottom: 1rem !important;
  }
  .magazine-navigation .navigation-bar .inner-links li {
    margin-top: 10px;
  }
  .magazine-navigation .navigation-bar .sm-links ul li a {
    margin-right: 10px;
    margin-left: 0;
  }
}
