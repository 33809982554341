.events-section .swiper-slide {
    height: calc((100% - 30px) / 2) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.events-section .event-card {
    /* padding: 1rem; */
    position: relative;
    display: flex;
    align-items: flex-end;
    aspect-ratio: 1/1;
    border-radius: 10px;
    overflow: hidden;
}

.events-section .event-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.events-section .event-card .content {
    padding: 1rem;
    background: var(--main-color-one);
}

.events-section .event-card .content h4 {
    text-transform: uppercase;
    color: var(--font-dark);
    font-family: "headingbold" !important;
}
.events-section .swiper-pagination-bullet-active{
    background: var(--theme-color) !important;
}
@media (max-width:992px) {
    .events-section .event-card{
        aspect-ratio: 1/1.5;
    }
}