.add-banner {
    width: 100%;
    height: 55vh;
}

.add-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (max-width:992px) {
    .add-banner{
        height: 60vh;
    }
}
@media(max-width:768px){
    .add-banner {
        margin-top: 4rem;
    }

}
@media(max-width:576px){

    .add-banner{
        height: 40.5vh;
    }
}

@media(max-width:450px){

    .add-banner{
        height: 35.5vh;
    }
}