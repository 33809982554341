 /* .scroll{
    display:flex;
    gap: 1rem;
    overflow-x:auto;
}  */

.about-dsa-section .dsa-card {
    padding: 1rem;
    border-radius: 20px;
    /* background: var(--main-color-two); */
     background:#E6E4E3;
    /* display: flex; */
    position: relative;
    height: 100%;
}

.about-dsa-section .dsa-card .box {
    display: flex;
    align-items: center;
    /* margin-top: 1rem; */
    justify-content: center;
}

.about-dsa-section .dsa-card .count {
    width: 100%;
    text-align: center;
    font-size: 2.3rem;
    /* position: absolute; */
    transform: translateY(-2.9rem);
    margin-bottom: -3.5rem;
}

.about-dsa-section .dsa-card .image {
    border-radius: 15px;
    overflow: hidden;
    width: 40%;
    aspect-ratio: 1/1;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: var(--main-color-one); */
    /* background: var(--main-color-two); */
    background-color: #EBEBEB;
}

.about-dsa-section .dsa-card .image img {
     width: 80%; 
    /* height: 100%; */
    /* width: 100px; */
    object-fit: contain;
}

.about-dsa-section .dsa-card .content {
    
    width: 70%;
}

.about-dsa-section .dsa-card .content .name {
    font-weight: bolder;
    font-family: "bodyfontbold" !important;
    margin-bottom: 4px !important;
    font-size: 1rem;
    color: var(--theme-color);
    line-height: 1.3rem;
}
.about-dsa-section .dsa-card .content p{
    font-size: .7rem;
}
.about-dsa-section .dsa-card::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -22px;
    width: 20px;
    height: 2px;
    background-color: #000000;
}
.about-dsa-section .last-dsa-card:last-child::after{
    display: none;
}





@media(max-width:992px){
    .about-dsa-section .col-6{
        margin-top: 2rem;
    }
    .about-dsa-section .dsa-card .image {
        width: 30%;
    }
    .about-dsa-section .dsa-card::after {
        display: none;
        /* content: '';
        position: absolute;
        top: 50%;
        right: -22px;
        width: 20px;
        height: 2px;
        background-color: #000000; */
    }
}

@media(max-width:768px){
    .about-dsa-section .dsa-card .count {
        font-size: 1.8rem;
        transform: translateY(-2.4rem);
        margin-bottom: -2.7rem;

    }
    .about-dsa-section .dsa-card .content .name {
        font-size: 0.9rem;
    }

}

@media(max-width:576px){
    .about-dsa-section .dsa-card .count {
        font-size: 1.5rem;
        transform: translateY(-2.4rem);
        margin-bottom: -2.3rem;

    }
    .about-dsa-section .dsa-card .content .name {
        font-size: 0.8rem;
    }

    .about-dsa-section .dsa-card .content p {
        font-size: .6rem;
    }

}
@media(max-width:375px){
    .about-dsa-section .dsa-card .box {
        display:flex;
        flex-direction: column;
    }
    .about-dsa-section .dsa-card .image {
        width: 50%;
    }
    
.about-dsa-section .dsa-card .content {
    width: 100%;
}
.about-dsa-section .dsa-card .content .name {
    font-size: 0.7rem;
    line-height: 1.4;
}
}












/* 
 @media(max-width:992px){
     .scroll{
    display:flex;
    overflow-x:auto;
      }
      
.about-dsa-section .dsa-card .count {
    width: 100%;
    text-align: center;
    font-size: 2.3rem;
    position: absolute;
    transform: translateY(-2.9rem);
}

 } */








/*  
@media (max-width:992px) {
   
    .about-dsa-section .dsa-card .count {
        font-size: 2rem;
        transform: translateY(-2.6rem);
    }

    .about-dsa-section .dsa-card .content .name {
        font-size: 0.8rem;

    }
    .about-dsa-section .dsa-card .content p{
        font-size: 0.5rem;
    }
     
    .about-dsa-section .dsa-card .count {
        left: 0;
    }
}  */




/* 
@media (max-width:768px) {
    .about-dsa-section .col-md-6.col-lg-3{
        margin-bottom: 4rem;
    }
    .about-dsa-section .dsa-card::after{
        right: 0;
        left: 50%;
        top: initial;
        bottom: -25px;
        transform: translateX(-50%) rotate(90deg);
    }

    .about-dsa-section .dsa-card .content .name{
    
    font-size: 1.4rem;
    line-height: 1.8rem;    

    }
    .about-dsa-section .dsa-card .content p{
        font-size: 0.9rem;
    }
    
.about-dsa-section .dsa-card .image{
    width: 23%;
    aspect-ratio: 1/0.7;
}

.about-dsa-section .dsa-card .content {
    
    width: 77%;
}
.about-dsa-section .dsa-card .image img{
    width: 68%;
}

     
    .about-dsa-section .dsa-card .count {
        left: 0;
    }
} 
 */


/* 
@media (max-width:576px){

    .about-dsa-section .dsa-card .content .name{
 
    font-size: 1rem;
    line-height: 1.2rem;

    }
    
    .about-dsa-section .dsa-card .content p{
        font-size: 0.7rem;
    }

} */