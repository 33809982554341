.category-filter .category-tab p{
    color: var(--theme-color);
}
.indian-army-section .main-heading h2 {
    font-size: 3rem;
    font-family: "headingbold" !important;
}

.indian-army-section .left-section .image {
    padding: 1rem;
    border-radius: 20px;
    background: var(--main-color-one);
    height: 90%;
    
}

.indian-army-section .left-section .image img {
    border-radius: 15px;
    height: 100%;
    width: 100%;
    
     

}
.indian-army-section .right-section p{
    font-size: 1.42vw;
    /* font-size: 1.38rem; */
}
.indian-army-section .right-section b {
    margin-bottom: 1rem;
    display: block;
}

.indian-army-section .right-section img {
    width: 40%;
    margin-bottom: 1rem;
}

.indian-army-section .right-section ul p {
    padding-left: 10px;
}
.defsec-cat:hover, .defsec-cat.Active{
    color: var(--theme-color);
}

/* .indian-army-section .right-section p {
    margin-bottom: 1rem !important;
    }
.indian-army-section .right-section ul p {
    margin-bottom: 0 !important;
    } */

    .indian-army-section .right-section p img {
        margin-right: 1rem;
    }



@media (max-width:1400px){
.indian-army-section .right-section p{
    font-size: 1.27vw;  
}
}
@media (max-width:1200px){
           
.indian-army-section .right-section p{
    font-size: 1vw; 
}
}

@media (max-width:992px) {
    .indian-army-section .main-heading h2 {
        font-size: 2rem;
    }

    .indian-army-section .left-section{
        aspect-ratio: 1/0.8;
    }
    .indian-army-section .left-section .image {
        /* margin-bottom: 3rem; */
        margin: 0;
        /* height: 68%; */
    }
    .indian-army-section .left-section .image img{


    }
    .indian-army-section .right-section{
        /* margin-top: 3rem; */
    }

    .indian-army-section .right-section p img {
        width: 100% !important;
    }
    
.indian-army-section .right-section b {
    font-size: 1.5rem;
}
              
.indian-army-section .right-section p{
    font-size: 3vw; 
}
}

@media(max-width:576px){
    .indian-army-section .main-heading h2 {
        font-size: 1.9rem;
    }
    .indian-army-section .right-section b {
        font-size: 1.1rem;
    }
    .indian-army-section .right-section p img {
        width: 50% !important;

    }
    .indian-army-section .right-section img {
        margin-bottom: 0rem;
    }
    .indian-army-section .left-section .image {
        height: 95%;
    }
    .indian-army-section .right-section p {
        font-size: 3.5;
    }
}
 @media(max-width:450px){
    .indian-army-section .right-section p {
        font-size: 3.5vw;
    }

 }
 
 @media(max-width:375px){
    .indian-army-section .right-section p {
        font-size: 4.3vw;
    }

 }