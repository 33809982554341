    .podcast-event-page-podcast-section {
    background: var(--main-color-two);
}

.podcast-event-page-podcast-section .main-heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    letter-spacing: 2px;
    color: var(--font-dark) !important;
}

.podcast-event-page-podcast-section .main-heading span {
    font-size: 6rem;
    font-family: "heading" !important;
    font-style: italic;
    font-weight: 100;
}

.podcast-event-page-podcast-section .top-section b {
    display: block;
    text-transform: capitalize;
    font-weight: bolder !important;
}

.podcast-event-page-podcast-section .top-section p {
    font-size: 1.5rem;
    /* margin-bottom: 10px !important; */
}

.podcast-event-page-podcast-section .top-section a {
    /* padding: 10px 30px;
    background-color: var(--black-color);
    border-radius: 20px;
    color: var(--white-color) !important; */
    margin-top: 2rem;
    margin-bottom: 3rem;
    /* display: inline-block; */
}

.podcast-event-page-podcast-section .center-section img {
    border-radius: 20px;
    width: 100%;
    filter: grayscale(1) contrast(1) brightness(1);
}

.podcast-event-page-podcast-section .center-section .left-img {
    transform: translateX(50px);
    height: 500px;
}

.podcast-event-page-podcast-section .center-section .right-img {
    transform: translateX(-50px);
    height: 400px;
    border: 1px solid;
}
.podcast-event-page-podcast-section .center-section .center-heading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
}
.podcast-event-page-podcast-section .center-section .center-heading h2{
    font-size: 9rem;
    font-family: "headingbold" !important;
    color: var(--font-light);
    text-transform: uppercase;
}


@media(max-width:1400px){
    
    .podcast-event-page-podcast-section .main-heading {
        font-size: 2.5rem;
    }
    .podcast-event-page-podcast-section .center-section .center-heading h2 {
        font-size: 7.2rem;
    }


}


@media(max-width:1200px){


    .podcast-event-page-podcast-section .main-heading {
        font-size: 2rem;
    }

    .podcast-event-page-podcast-section .main-heading span {
        font-size: 5.7rem;
    }
}
 

@media (max-width:992px) {
    .podcast-event-page-podcast-section .main-heading{
        font-size: 1.5rem;
    }
    .podcast-event-page-podcast-section .main-heading span {
        font-size: 2.5rem;
    }
    .podcast-event-page-podcast-section .center-section .center-heading h2{
        font-size: 4.9rem;
    }
    .podcast-event-page-podcast-section .top-section{
        display: flex;
        flex-direction: column;
      align-items: center;

    }
    
    .podcast-event-page-podcast-section .top-section p{
        width: 50%;
    }
     

    .podcast-event-page-podcast-section .center-section .left-img{
        transform: translateX(1rem);
        /* height: 300px; */
        /* height: 500px; */
        height: 394px;
        object-fit: cover;
    }
    .podcast-event-page-podcast-section .center-section .center-heading{
        text-align: center;
    }
    .podcast-event-page-podcast-section .center-section .right-img{
        /* width: 200px !important; */
        /* height: 200px; */
        height: 329px;
        object-fit: cover;
        transform: translateX(-40px);
    }

    .podcast-event-page-podcast-section .bottom-section{
        display: flex;
        flex-direction: column;
        width: 50%;
    }


}

@media(max-width:768px){
     
    .podcast-event-page-podcast-section .top-section p{
        width: 66%;
    }
    .podcast-event-page-podcast-section .center-section .center-heading h2{
        font-size: 3.8rem;
    }
     
    
    .podcast-event-page-podcast-section .top-section p{
       font-size: 1.3rem;
    }

}


@media(max-width:576px){
     
    .podcast-event-page-podcast-section .top-section p{
        width: 80%;
    }
    .podcast-event-page-podcast-section .center-section .center-heading h2{
        font-size: 3rem;
    }
    
    .podcast-event-page-podcast-section .top-section p{
       font-size: 1.1rem;
    }

    .podcast-event-page-podcast-section .center-section .right-img{
        
        height: 220px;
    }
    
    .podcast-event-page-podcast-section .center-section .left-img{
      
        height: 279px;
    }
    .podcast-event-page-podcast-section .bottom-section{
        
        width: 80%;
    }



}