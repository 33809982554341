.about-section::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 90px;
  /* transform: translateY(-50%); */
  width: 100%;
  /* height: 84%; */
  height: 81.5%;
  background: #ecebeb;
  z-index: -1;
}

.about-section .left-section {
  margin-left: -5.7rem;
}
.about-section .left-section img {
  width: 100%;
  /* height: 70%; */
  border-radius: 10px;
  margin-bottom: -4rem;
}

.about-section .left-section > img {
  /* height: 500px; */
  /* width: 100vw; */
  height: 82vh;
  object-fit: fill;
  object-fit: contain;
  object-position: center;
  margin-top: -0.2rem;
  border-radius: 10px;
}

.about-section .left-section .content {
  /* background: var(--main-color-two); */
  width: 62%;
  /* height: %; */
  margin: 0 auto;
  border-radius: 20px;
  /* transform: translateY(-80px); */
  transform: translateY(-78px);
  position: relative;
}

.about-section .left-section .content .inner-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px;
  /* padding-bottom: 4rem; */
  text-align: center;
  height: 500px;
  /* width: 200px; */
}

.about-section .left-section .content .inner-content h2 {
  font-size: 1.7rem;
}

.about-section .left-section .content .inner-content p {
  /* font-size: .9rem; */
  font-size: 0.9rem;
  font-weight: bold;
  width: 95%;

  margin: 0 auto;
  margin-top: 0.5rem;
}

.about-section .left-section .content .learn-more-btn {
  position: absolute;
  bottom: -75px;
  left: 50%;
  transform: translateX(-50%);
  /* background: var(--white-color); */
  /* padding: 10px; */
  /* padding-top: 20px;
    border-radius: 20px 20px 0 0; */
}

.about-section .left-section .content .learn-more-btn a {
  padding: 10px 35px;
  font-weight: 500;
  background: var(--theme-color);
  color: var(--white-color) !important;
  text-align: center;
  border-radius: 20px;
}

.about-section .about-section-swiper .swiper-slide,
.about-section .about-section-swiper img {
  border-radius: 10px !important;
  width: 100%;
  height: 90%;
  cursor: pointer;
  background: transparent !important;
  object-fit: cover;
}

.about-section .about-section-swiper .swiper-slide {
  text-align: center;
}

.about-section .about-section-swiper .mySwiper2 img {
  width: 100%;
  height: 53vh;
  /* padding: 10px; */
  object-fit: contain;
  transform: rotate(-5deg);
  margin: 0 auto;
}

.about-section .about-section-swiper .mySwiper2 {
  /* height: 443px !important; */
  height: auto!important;
  border-radius: 25px;
  /* margin-bottom: 1rem; */
  /* padding-bottom: 1rem; */
}

/*  
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 1rem;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: 1rem;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
} */

.about-section .about-section-swiper .mySwiper {
  height: 130px;
  padding-bottom: 1.3rem;
}
.about-section .about-section-swiper .mySwiper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-section .about-section-swiper .mySwiper .swiper-wrapper {
  /* margin-bottom: 5rem; */
  /* margin: 0 2rem; */
}

.about-section
  .about-section-swiper
  .mySwiper
  .swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
}

.about-section .about-section-swiper p {
  color: var(--theme-color);
  font-weight: bolder;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.2rem;
  line-height: 1;
  margin-top: -10px;
}

.about-section .about-section-swiper p span {
  font-size: 3rem;
}

.about-section .about-section-swiper .about-swiper-top .swiper-slide {
  padding: 1rem;
}
/* 
.about-section .about-section-swiper .mySwiper .swiper-slide {
  padding: 0.5rem;
} */






@media(max-width:1400px){
  .about-section .left-section .content {
     width: 77%;
    /* width: 550px!important; */
  }
  .about-section .left-section .content .inner-content p {
    /* width: 39vw; */
  }
  .about-section .about-section-swiper .mySwiper2 {
    height: 57%!important;
  }
  .about-section .about-section-swiper p {
    font-size: 0.9rem;
  }
  .about-section .about-section-swiper{
    margin-left: 1vw;
  }
  .about-section .about-section-swiper .mySwiper2 {
    /* height: 59vh !important; */
  }

}



@media(max-width:1200px){
  .about-section .about-section-swiper p {
    font-size: 0.8rem;
  }
  .about-section .left-section .content {
    width: 78%;
}
.about-section .left-section .content .inner-content p {
  font-size: 0.7rem;
}


}


@media(max-width:1100px){
  .about-section .left-section .content .inner-content p {
    font-size: 0.6rem;
  }
  
  
}

@media (min-width: 992px) {
  .about-section .left-section {
    /* padding-right: 5rem; */
  }
}

@media (max-width: 992px) {
  .about-section .left-section .content {
    /* width: 90%; */
    width: 75%;
    transform: translateY(-78px);
  }

  .about-section .left-section .bg-shape {
    height: 250px;
  }

  .about-section .left-section .content .inner-content p {
    /* display: none; */
    font-size: 0.7rem;
  }
  .about-section .left-section .content .inner-content h2 {
    font-size: 1.3rem;
  }

  .about-section .left-section .content .learn-more-btn {
    width: 90%;
    text-align: center;
    font-size: 0.7rem;
    bottom: -75px;
  }

  .about-section .left-section .content .inner-content {
    padding: 20px;
  }
  .about-section.position-relative {
    padding-top: 20px;
    /* margin-bottom: 4rem; */
  }
  /* .about-section .about-section-swiper .swiper-slide{
        
        aspect-ratio: 1/0.6;
        

    } */
  .about-section .about-section-swiper h2 {
    font-size: 2rem;
  }
  .about-section .about-section-swiper p {
    font-size: 0.8rem;
  }

  .about-section .about-section-swiper .mySwiper {
    margin-top: 0.6rem;
  }
  .about-section .about-section-swiper .mySwiper2 img {
    width: 94%;
  }

  .about-section .about-section-swiper .mySwiper2 .swiper-slide img {
    /* height: 50%; */
  }

  .about-section .about-section-swiper .mySwiper2,
  .about-section .about-section-swiper .swiper-slide,
  .about-section .about-section-swiper img {
    height: auto !important;
  }

  .about-section::before {
    height: 94%;
    top: 30px;
  }
}

@media (max-width: 767.98px) {
  .about-section::before {
    height: 97.5%;
    /* height: auto; */
  }
  .about-section .left-section .content {
    /* width: 90%; */
    width: 65%;
    transform: translateY(-78px);
  }

  .about-section .left-section {
    margin-left: 0rem;
  }
  .about-section .about-section-swiper .mySwiper {
    height: 22%;
    margin-top: 1rem;
  }

  .about-section .left-section .content .inner-content {
    padding: 20px;
    height: auto;
  }
  .about-section .about-section-swiper h2 {
    margin-top: 4rem !important;
  }
  .about-section .about-section-swiper .mySwiper2 .swiper-slide img {
    aspect-ratio: 1/0.6;
  }
  .about-section .about-section-swiper .mySwiper img {
    aspect-ratio: 1/0.8;
  }

  .about-section .left-section > img{
     height: auto;
     margin-bottom: 2rem;
     border-radius: 0px;
  }
}

@media (max-width: 576px) {
  .about-section .left-section .content {
    width: 85%;
    transform: translateY(-78px);
  }

  .about-section .left-section .bg-shape {
    /* height: 250px; */
    height: 235px;
  }

  .about-section .left-section .content .inner-content p {
    /* display: none; */
    font-size: 0.6rem;
  }
  .about-section .about-section-swiper .mySwiper2 .swiper-slide img {
    aspect-ratio: 1/0.7;
  }
}
@media(max-width:375px){
  .about-section .left-section .content .inner-content {
    padding: 10px;
  }
}