.awards-page .category-tab {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.awards-page .category-tab p {
    font-size: 1.4rem;
    font-weight: bolder;
    color: var(--theme-color);
}

.awards-page .category-tab ul {
  display: flex;
}

.awards-page .category-tab ul li {
  font-weight: bolder;
  cursor: pointer;
  margin-left: 1.5rem;
}

.awards-page .main-heading h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "headingbold" !important;
  margin-bottom: 3rem;
}

.awards-page .image-section img {
  /* width: 50%; */
  width: 40%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain;
}

.awards-page b {
  font-size: 1.3rem;
  font-family: "bodyfontbold" !important;
}

.awards-page p b {
  font-size: 1rem;
  font-family: "bodyfont" !important;
}

.awad-page-card {
  margin-bottom: 3rem;
}

.awad-page-card:nth-child(odd) {
  flex-direction: row-reverse;
}
.category-tab.Active,
.category-tab li:hover {
  color: var(--theme-color);
  font-weight: bolder;
}

@media (max-width: 992px) {
  .awards-page .category-tab {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }

  .awards-page .category-tab ul {
    /* overflow-x: auto; */
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;
  }

  .awards-page .category-tab ul li {
    margin-left: 0;
    margin-bottom: 0.7rem;
    /* display: inline-block;
        width: fit-content; */
    /* min-width: 100px; */
  }
  .awards-page .main-heading h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .awad-page-card .image-section {
    aspect-ratio: 1/0.4;
  }
}
@media(max-width:576px){
  .awad-page-card .image-section {
    aspect-ratio: 1/0.6;
  }
}