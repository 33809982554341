.dsa-ups-section .mySwiper{
    padding-bottom: 3rem;
}
.dsa-ups-section .mySwiper .swiper-pagination-bullet-active{
    background-color: var(--theme-color) !important;
}
.dsa-ups-section .mySwiper .swiper-slide{
    height: auto;
}
.dsa-ups-section .dsa-card {
    padding: 1rem;
    border-radius: 20px;
    background: var(--main-color-two);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    height: 100%;
}

.dsa-ups-section .dsa-card .image {
    border-radius: 15px;
    overflow: hidden;
    /* width: 45%; */
    width: 25%;
    aspect-ratio: 1/1;
    margin-bottom: 10px;
}

.dsa-ups-section .dsa-card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dsa-ups-section .dsa-card .content {
    /* width: 55%; */
    font-size: .9rem;
}
.dsa-ups-section .dsa-card .content .name {
    font-size: 1rem;
    font-weight: 500;
    font-family: "bodyfontbold" !important;
    margin-bottom: 6px !important;
    color: var(--theme-color);
    text-align-last: center;
}