.magazine-navigation h1 {
  font-size: 4.8rem;
  text-align: center;
  color: var(--font-dark);
}
.magazine-navigation h1 span {
  color: var(--font-dark);
  font-family: "bodyfontbold" !important;
}

.active {
  color: var(--theme-color) !important;
  font-family: bodyfontbold;
}

.magazine-navigation .navigation-bar .name {
  font-size: 1.2rem;
  /* font-family: "bodyfontbold" !important; */
}
.magazine-navigation .navigation-bar {
  background: var(--main-color-one);
  padding: 10px 2rem !important;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.magazine-navigation .navigation-bar .inner-links {
  display: flex;
  border-bottom: 1px solid var(--black-color);
  margin-left: 8px !important;
  margin-bottom: 10px !important;
  width: 100%;
  justify-content: space-evenly;
  font-size: 1.2rem;
  letter-spacing: 2px;
}

.magazine-navigation .navigation-bar .sm-links ul {
  display: flex;
}
.magazine-navigation .navigation-bar .sm-links ul li a {
  background: var(--main-color-two);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.inner-links li a:hover,
.navigation-bar .name a:hover {
  color: var(--theme-color) !important;
  font-weight: bold;
}

@media (max-width: 1400px) {
  .magazine-navigation h1 {
    font-size: 4.1rem;
  }
}

@media (max-width: 1200px) {
  .magazine-navigation h1 {
    /* font-size: 3.4rem; */
    font-size: 4rem !important;
  }
}

@media (max-width: 992px) {
  .magazine-navigation h1 {
    font-size: 2.5rem !important;
  }
}

@media(max-width:768px){
  .magazine-navigation h1{
    font-size: 2rem !important;
  }
}

@media(max-width:576px){
  .magazine-navigation h1{
    font-size: 1.7rem !important;
  }
}

@media(max-width:450px){
  .magazine-navigation h1{
    font-size: 1.4rem !important;
  }
}

@media(max-width:375px){
  .magazine-navigation h1{
    font-size: 1.15rem !important;
  }
}