.ceo-section .ceo-card {
    background-color: var(--main-color-one);
    padding: 1rem;
    border-radius: 20px;
    text-align: center;
}

.ceo-section .ceo-card img {
    border-radius: 15px;
    width: 100%;
    aspect-ratio: 1/0.8;
    object-fit: cover;
    margin-bottom: 1rem;
    object-position: top;
    /* object-position: center; */
    filter: grayscale(1) contrast(1) brightness(1);
    mix-blend-mode: multiply;
}

.ceo-section .ceo-card .name {
    font-size: 2rem;
    font-family: "bodyfontbold" !important;
    text-transform: uppercase;
}

.ceo-section .ceo-card .name span {
    color: #636669;
}
.ceo-section .ceo-card > span{
    font-size: 1.3rem;
    font-style: italic;
    margin-bottom: 1rem;
    display: inline-block;
}

.ceo-section .ceo-card .text-end{
    font-size: .8rem;
}

.ceo-section .ceo-card .text-end a{
color: var(--theme-color) !important;
}


.ceo-section .ceo-card .text-end a:hover{
    
 text-decoration: underline !important;
 /* color: var(--theme-color) !important; */
}


@media (max-width:992px) {
     
    .ceo-section .ceo-card {
        /* padding: 1rem; */
        /* margin-bottom: 3rem; */
        
    }
    .ceo-section .ceo-card .name{
        font-size: 1.5rem;
    }
}

@media (max-width:768px) {
     
    .ceo-section .ceo-card {
        /* padding: 1rem; */
        margin-bottom: 3rem;
        
    }
    .ceo-section .ceo-card img {
        aspect-ratio: 1/0.7;
    }
    .ceo-section .ceo-card .name{
        font-size: 1.2rem;
    }
    ceo-section .ceo-card > span {
        font-size: 1rem;
    }
}

@media (max-width:576px) {
    .ceo-section .ceo-card .name{
        font-size: 1.5rem;
    }
    ceo-section .ceo-card > span {
        font-size: 1.2rem;
    }

}
