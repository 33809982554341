.welcome-section .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 70%;
    background: var(--main-color-one);
}

.welcome-section h2 {
    text-transform: uppercase;
    font-size: 3rem;
    color: var(--font-dark);
    letter-spacing: 2px;
    font-family: "headingbold" !important;
}

.welcome-section b {
    font-size: 1.2rem;
    display: block;
    letter-spacing: 2px;
    margin-bottom: 2rem;
}

.welcome-section p {
    font-size: 1.2rem;
    margin-bottom: 4rem !important;
    text-transform: uppercase;
}

.welcome-section .welcome-card-box {
    display: flex;
    justify-content: space-evenly;
}

.welcome-section .welcome-card-box .welcome-card {
    width: 25%;
    aspect-ratio: 1/1;
    padding: 1rem;
    background: var(--main-color-two);
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: var(--font-dark);
}

.welcome-section .welcome-card-box .welcome-card h3{
    font-size: 2rem;
    font-family: "bodyfontbold" !important;
}
.welcome-section .welcome-card-box .welcome-card svg {
    font-size: 5rem;
    margin-bottom: 1rem;
}

.welcome-section .welcome-card-box .welcome-card.active {
    background: var(--theme-color);
    color: var(--white-color) !important;
}

@media(max-width:1400px){
    .welcome-section .bg{
        height: 75%;
    }
}

@media(max-width:1200px){
    .welcome-section .welcome-card-box .welcome-card h3 {
        font-size: 1.4rem;
    }
    /* .welcome-section .bg{
        height: 75%;
    } */

}



@media (max-width:992px) {
    .welcome-section h2{
        font-size: 2rem;
        width: 100%;
    }
    .welcome-section .col-lg-8{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .welcome-section b{
        width: 74%;
        font-size: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

    .welcome-section .welcome-card-box {
        overflow-x: auto;
        gap: 2rem;
    }
    .welcome-section .welcome-card-box .welcome-card{
        width: fit-content;
    }
    .welcome-section .bg{
        height: 75%;
    }
}
@media(max-width:768px){
    .welcome-section b{
        width: 86%;
    }
    .welcome-section .bg {
        height: 79%;
    }

}

@media(max-width:576px){
    .welcome-section b{
        width: 86%;
    }
    .welcome-section .bg {
        height: 81%;
    }
    .welcome-section h2 {
        font-size: 1.3rem;
    }

   


}
@media(max-width:449.98px){
    .welcome-section .welcome-card-box .welcome-card {
        padding: 0.5rem;
    }
    .welcome-section .welcome-card-box .welcome-card svg {
        font-size: 3rem;
    }
    .welcome-section .welcome-card-box .welcome-card h3 {
        font-size: 1rem;
    }
    .welcome-section .bg {
        height: 85%;
    }
    
}
@media (max-width:375px){
    .welcome-section .bg {
        height: 87%;
    }
}