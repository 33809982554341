.article-detail-section .article-card {
    /* display: flex; */
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--font-light);
    margin-bottom: 1rem;
}

.article-detail-section .article-card .image {
    width: 100%;
    margin-right: 2rem;
    /* padding: 1rem; */
}

.article-detail-section .article-card .image img {
    aspect-ratio: 1/1;
    width: 100%;
   
    /* border-radius: 50%; */
    object-fit: cover;
    

}
.article-detail-section .article-card p{
    font-size: 1rem;
}
.article-detail-section .article-card .name {
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 1.2rem;
    letter-spacing: 2px;
    color: var(--font-dark);
    margin-top: 2rem;
}

.article-detail-section .date .box {
    display: flex;
    margin-bottom: 0.8rem;
}

.article-detail-section .date .box .left {
    width: 50%;
    font-family: "bodyfontbold" !important;
    text-transform: capitalize;
    font-size: 1rem;
}
.article-detail-section .date .box p{
    font-size: 1rem;
}
.article-detail-section .date .box ul {
    display: flex;
    gap: 0 10px;
}
.article-detail-section .date .box ul svg{
    transition: .3s;
    font-size: 1.4rem;
}
.article-detail-section .date .box ul svg:hover{
    color: var(--theme-color);
}

.article-detail-section b.d-block {
    text-transform: capitalize;
}
.article-detail-section .scroll-section{    
    max-height: 100vh;
    padding-right: 1rem;
    /*  overflow-y: auto; */
    overflow-y: scroll;
}

    

/* }
.article-detail-section .scroll-section p{
    font-size: 5rem;

}
  */
  

  @media (max-width:992px){
    .article-detail-section .article-card {        
        border-bottom: none;
       
    }
    .article-detail-section .date{
      border-bottom: 1px solid var(--font-light);
    }
    .article-detail-section .article-card .image img {
        width: 50%;
        /* aspect-ratio: 1/0.38; */
        aspect-ratio: 1/0.6;
        object-position: top;
  }
 
}

@media(max-width:576px){
    .article-detail-section .article-card .name {
        font-size: 0.9rem;
    }
    .article-detail-section .date .box ul svg{
       
        font-size: 1.1rem;
    }
    .article-detail-section .article-card .image {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .article-detail-section .article-card .image img {
        aspect-ratio: 1/1;
    }

}
    
  