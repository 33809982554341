.alert-page .category-tab {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.alert-page .category-tab p {
    font-size: 1.4rem;
    font-weight: bolder;
}

.alert-page .category-tab ul {
    display: flex;
}

.alert-page .category-tab ul li {
    cursor: pointer;
    margin-left: 1.5rem;
}

.alert-page .main-heading h2 {
    text-align: center;
    font-size: 3rem;
    font-family: "headingbold" !important;
    margin-bottom: 3rem;
}



.alert-page .careers-category ul {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: bolder;
    margin-bottom: 2rem !important;
}

.alert-page .careers-category ul p {
    cursor: pointer;
}

.alert-page .alert-news-card {
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;
}

.alert-page .alert-news-card:nth-child(even) {
    flex-direction: row-reverse;
}

.alert-page .alert-news-card:nth-child(even) .content {
    text-align: right;
}

.alert-page .alert-news-card:last-child {
    margin-bottom: 0;
}

.alert-page .alert-news-card .image {
    width: 40%;
}

.alert-page .alert-news-card .image img {
    border-radius: 20px;
    height: 100%;
    width: 100%;
}

.alert-page .alert-news-card .content {
    width: 60%;
    background: var(--main-color-one);
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.alert-page .alert-news-card .content h3 {
    font-family: "bodyfontbold" !important;
    margin-bottom: 1rem !important;
    text-transform: uppercase;
}

.alert-page .alert-news-card .content a {
    padding: 5px 20px;
    /* border: 1px solid var(--black-color);
    border-radius: 20px; */
    font-size: 1rem;
}

@media (max-width:992px) {
    .alert-page .category-tab {
        flex-direction: column;
        align-items: flex-start;
    }

    .alert-page .category-tab ul {
        /* overflow-x: auto; */
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }

    .alert-page .category-tab ul li {
        margin-left: 0;
        margin-bottom: .7rem;
        /* display: inline-block;
        width: fit-content; */
        /* min-width: 100px; */
    }
    .alert-page .alert-news-card:nth-child(even),
    .alert-page .alert-news-card{
        flex-direction: column;
    }
    .alert-page .alert-news-card .content,
    .alert-page .alert-news-card .image{
        width: 100%;
    }
    .alert-page .alert-news-card:nth-child(even) .content{
        text-align: left;
    }
}