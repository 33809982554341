.editions-category-section .category-tab {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.editions-category-section .category-tab p {
    font-size: 1.4rem;
    font-weight: bolder;
    margin-bottom: 0;
    color: var(--theme-color);
}

.editions-category-section .category-tab ul {
    display: flex;
}

.editions-category-section .category-tab ul li {
    cursor: pointer;
    margin-left: 1.5rem;
    font-weight: bolder;
    transition: .3s;
}

.editions-category-section .category-tab ul li:hover {
    color: var(--theme-color);
}

.editions-category-section .select-edition{
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    margin-bottom: 1rem;
    border: none;
}
.editions-category-section .select-edition label:hover{
    color: var(--theme-color);

}
.editions-category-section .select-edition select{
    border: none;
    
}




.editions-category-section .edition-card {
    border: 1px solid #e3e3e3;
    background: #fcfcfc;
    
    
     
}

/* .editions-category-section .edition-card:nth-child(5n + 0) {
    border-left: 0;
    border-right: 0;
} */

/* .editions-category-section .edition-card:nth-last-child(+n-3){ */
.editions-category-section .edition-card {
    /* border-top: 0; */
}

/* .editions-category-section .edition-card:nth-child(1),
.editions-category-section .edition-card:nth-child(2),
.editions-category-section .edition-card:nth-child(3),
.editions-category-section .edition-card:nth-child(4) {
    border-top: 1px solid var(--font-dark) !important;
} */

.editions-category-section .edition-card .date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    font-weight: bolder;
}

.editions-category-section .edition-card .image img {
    width: 100%;
    margin-bottom: 1rem;
}

.editions-category-section .edition-card .box {
    padding: 1.5rem 10px;
}

.editions-category-section .edition-card .box h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--font-light);
}

.editions-category-section .edition-card .des {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

@media (max-width:992px) {
    .editions-category-section .edition-card {
        /* border: 0; */
    }

    .editions-category-section .edition-card .box {
        /* border: 1px solid #e3e3e3; */
        padding: 1rem;
    }
}

@media (max-width:992px) {
    .editions-category-section .category-tab {
        flex-direction: column;
        align-items: flex-start;
    }

    .editions-category-section .category-tab ul {
        overflow-x: auto;
        margin-top: 1rem;
    }

    .editions-category-section .category-tab ul li {
        margin-left: 0;
        margin-right: 1.2rem;
    }
}
@media(max-width:576px){
    .edition-card .date p{
        font-size: 0.9rem;

    }
}