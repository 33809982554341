.article-category-section .category-tab {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}

.article-category-section .category-tab p {
  font-size: 1.4rem;
  font-weight: bolder;
  color: var(--theme-color);
}

.article-category-section .category-tab ul {
  display: flex;
}

.article-category-section .category-tab ul li {
  font-weight: bolder;
  cursor: pointer;
  margin-left: 1.5rem;
  transition: 0.3s;
}
.article-category-section .category-tab ul li:hover {
  color: var(--theme-color);
}

.article-category-section .article-card {
  display: flex;
  align-items: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid var(--main-color-two);
  margin-bottom: 1rem;
  width: 98%;
}

.article-category-section .article-card:last-child {
  /* border: 0;
    margin-bottom: 0; */
}

.article-category-section .article-card .image {
  width: 26%;
  /* aspect-ratio: 1/1; */
  aspect-ratio: 1/0.6;
  margin-right: 1rem;
}

.article-category-section .article-card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.article-category-section .article-card .content {
  width: 74%;
}
.article-category-section .article-card .content p b{
  color: var(--theme-color);
  font-family: "headingbold" !important;
  font-size: 1.1rem;
}

.article-category-section .article-card .content .des {
   /* width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.article-category-section .article-card .content a {
  /* padding: 0 25px; */
  /* border: 2px solid var(--theme-color); */
  /* text-decoration: underline !important; */
  text-underline-offset: 5px;
  color: var(--theme-color) !important;
  background: transparent !important;
  border-radius: 40px !important;
  transition: 0.3s all;
  font-weight: bolder;
  display: inline-block;
  text-transform: capitalize;
  width: fit-content;
  font-size: 0.9rem;
  /* margin-right: 2rem; */
}

.article-category-section .article-card .content a:hover {
  /* color: var(--theme-color) !important; */
  text-decoration: underline !important;
}

/* .article-category-section .article-card .content a:hover {
    background: var(--theme-color) !important;
    color: var(--white-color) !important;
} */

.article-category-section .article-card .content .article-date {
  font-size: 0.9rem;
}

.article-category-section .magazine-card {
  margin-bottom: 6rem;
  margin-top: 1rem;
}

.article-category-section .magazine-card h2,
.article-category-section .magazine-card h5 {
  /* font-weight: bold; */
  color: var(--font-dark);
  letter-spacing: 2px;
  font-family: "headingbold" !important;
}

.article-category-section .magazine-card img {
  width: 93%;
  border-radius: 10px;
  margin-top: 0.8rem;
  /* height: 350px; */
  height: auto;
}
.category-tab .Active {
  color: var(--theme-color);
  font-weight: bold;
}
@media (max-width: 992px) {
  .article-category-section .category-tab {
    flex-direction: column;
    align-items: flex-start;
  }

  .article-category-section .category-tab ul {
    /* overflow-x: auto; */
    width: 100%;
    margin-top: 1rem;
    flex-direction: column;
  }

  .article-category-section .category-tab ul li {
    margin-left: 0;
    margin-bottom: 0.7rem;
    /* display: inline-block;
        width: fit-content; */
    /* min-width: 100px; */
  }
  .article-category-section .article-card {
    width: 100%;
    flex-direction: column;
  }
  .article-category-section .article-card .image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2rem;
    aspect-ratio: 1/0.38;
    height: 47%;
  }
  .article-category-section .article-card .image img {
    object-position: top;
  }
  .article-category-section .article-card .content {
    width: 100%;
  }
  .article-category-section .article-card .content .article-date {
    flex-wrap: wrap;
  }
  .article-category-section .article-card .content a {
    margin-left: auto;
  }
}

@media(max-width:576px){
  .article-category-section .print-magazine{
    display: none;
  }
  .article-category-section .magazine-card {
    margin-bottom: -2rem;
  }
}