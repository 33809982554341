.group-company-section .company-card {
    display: flex;
    margin-top: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.group-company-section .company-card a {
    display: inline-block;
    width: 18%;
    /* width: 20%; */
    aspect-ratio: 1/1;
    height: auto;
    border-radius: 50%;
    object-fit: contain;
    border: 5px solid var(--theme-color);
    transition: .5s;
    cursor: pointer;
    background: var(--white-color);
    overflow: hidden;
}

.group-company-section .company-card img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: .5s;
}

.group-company-section .company-card a:nth-child(1),
.group-company-section .company-card a:nth-child(2) {
    margin-right: -40px;
}

.group-company-section .company-card a:nth-child(4),
.group-company-section .company-card a:nth-child(5) {
    margin-left: -40px;
}

.group-company-section .company-card img:nth-child(5) {
    /* z-index: -1; */
}

.group-company-section .company-card a:nth-child(3) {
    z-index: 2;
}

.group-company-section .company-card a:nth-child(4) {
    z-index: 1;
}

.group-company-section .company-card a:hover {
    z-index: 9;
    transform: scale(1.1);
}

@media (max-width:992px) {
    .group-company-section .company-card img {
        /* width: 27%; */
        width: 100%;
    }
    .group-company-section .company-card a{
        width: 22%;
    }
}

 @media (max-width:768px) {
    .group-company-section .company-card img {
        /* width: 27%; */
        width: 100%;
    }
    .group-company-section .company-card a{
        width: 23%;
    }
}
 


@media (max-width:576px) {
    .group-company-section .company-card img {
        /* width: 27%; */
        width: 100%;
    }
    .group-company-section .company-card a{
        width: 24%;
    }
}


@media(max-width:449.98px){
    .group-company-section .company-card a {
        width: 26%;
    }
}
@media(max-width:374.98px){
    .group-company-section .company-card a {
        width: 27.5%;
    }
}


